import { LinearProgress } from "@material-ui/core";
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  ColumnEvent,
  ColumnState,
  ColumnVisibleEvent,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IServerSideGetRowsRequest,
} from "ag-grid-community";
import { AxiosError, AxiosInstance, Method } from "axios";
import { HighchartRef } from "components/summary/main/ChartCard";
import {
  ACTIVITY_KEY_MAPPING,
  ACTIVITY_SESSION_UPDATE_KEY,
  DASHBOARD_BENCHMARKING_FILTER_KEY_MAPPING,
  DASHBOARD_BENCHMARKING_SORT_KEY_MAPPING,
  DASHBOARD_ENTERPRISE_KEY_MAPPING,
  DASHBOARD_FORECAST_KEY_MAPPING,
  DASHBOARD_RENEWALS_SORT_KEY_MAPPING,
  EMAILID_COOKIE,
  EXECUTIVE_SHOW_ALERT,
  FEATURE_KEY,
  FEATURE_TOGGLES,
  FILTER_KEY_MAPPING,
  FILTER_TITLE_MAPPING,
  FIRST_NAME_COOKIE,
  KEYWORD_MAPPING,
  LAST_NAME_COOKIE,
  LAST_URL_COOKIE,
  MENU_DEFINITIONS,
  MOCK_USER_COOKIE,
  PlAYBOOK_KEY_MAPPING,
  PORTFOLIO_LAST_SEARCH_KEY,
  PORTFOLIO_SHOW_ALERT,
  RESOURCE_DEFINITION,
  ROLE,
  ROLE_COOKIE,
  ROLE_PRIORITY,
  ROW_HEIGHT,
  SORT_KEY_MAPPING,
  SUMMARY_ALERT_FILTER_KEY_MAPPING,
  SUMMARY_ALERT_SORT_KEY_MAPPING,
  TOKEN_COOKIE,
  UUID_COOKIE,
} from "constant";
import { saveAs } from "file-saver";
import { IncomingMessage, ServerResponse } from "http";
import jsCookie from "js-cookie";
import { clamp, random } from "lodash";
import moment from "moment";
import { useAxios } from "myAxios";
import { NextApiResponse } from "next";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { toast, ToastOptions } from "react-toastify";
import { GirdColumnDef, MenuItemDef, Toggle } from "types/app";
import { AuthDetail, FunctionInclude } from "types/auth";
import { SiteAccountType } from "types/dashboard/enterprise";
import {
  Account,
  CIOrgDetail,
  SiteInSiteSummary,
  SiteSummary,
} from "types/portfolio";
import {
  AggregatedActiveUserObject,
  AggregatedCallingObject,
  AggregatedLegacyMeetingUsageObject,
  AggregatedLegacyMeetingUtilizationObject,
  AggregatedMeetingUsageObject,
  AggregatedTeamUsageObject,
  Note,
  OldAggregatedCallingObject,
  SiteSearchOption,
  WebexAppDataUsage,
} from "types/summary";

export const Cookies = jsCookie.withConverter({
  write: function (value) {
    return unescape(value as string);
  },
  read: function (value) {
    return value;
  },
});

export const initPermissions = async (
  axios: AxiosInstance,
  fsAxios: AxiosInstance,
  uuid: string
): Promise<AuthDetail | null | number> => {
  const result: AuthDetail = {
    menus: [],
    features: [],
    role: ROLE.CISCO_WIDE_ROLE,
    toggles: undefined,
  };
  const skipFeatureCheck = process.env?.SKIP_FEATURE_CHECK === "true" ?? false;
  for (const [_k, v] of Object.entries(FEATURE_TOGGLES)) {
    result.toggles = {
      ...result.toggles,
      [v]: skipFeatureCheck,
    } as any;
  }
  try {
    const { data } = await axios.get<{
      functionsIncludes: FunctionInclude[];
      roleNames: ROLE[];
    }>("/api/auth/v2/user-permissions?service=SPNG");
    data.functionsIncludes.reduce((prv, cur) => {
      switch (cur.sourceType) {
        case "menu":
          prv.menus.push(cur.source);
          break;
        case "feature":
          prv.features.push(cur.source as FEATURE_KEY);
          break;
        default:
          break;
      }
      return prv;
    }, result);
    const { roleNames } = data;
    roleNames.sort((a, b) => {
      return (ROLE_PRIORITY?.[a] ?? 5000) - (ROLE_PRIORITY?.[b] ?? 5000);
    });
    result.role = roleNames?.[0] ?? ROLE.CISCO_WIDE_ROLE;

    if (!skipFeatureCheck) {
      try {
        const { data: toggleData } = await fsAxios.get<{
          featureToggles: Toggle[];
        }>(`/features/users/${uuid}/developer`);
        toggleData.featureToggles.forEach((toggle) => {
          if (toggle.key in result.toggles! && toggle.val === "true") {
            result.toggles![toggle.key as FEATURE_TOGGLES] = true;
          }
        });
      } catch (e) {
        console.error("Cannot get toggle service");
        console.error(e.response ?? e);
      }
    }
    /**
     * Workaround to kick out home menu
     */
    // if (result.role === ROLE.MANAGER_ROLE) {
    //   result.menus = result.menus.filter((x) => x !== "home");
    // }
    return result;
  } catch (e) {
    console.error(e?.response?.data?.message ?? e);
    return e.response?.status ?? null;
  }
};

export const getPermissionsByToken = async (
  axios: AxiosInstance
): Promise<Omit<AuthDetail, "toggles"> | null | number> => {
  const result: Omit<AuthDetail, "toggles"> = {
    menus: [],
    features: [],
    role: ROLE.CISCO_WIDE_ROLE,
  };
  try {
    const { data } = await axios.get<{
      functionsIncludes: FunctionInclude[];
      roleNames: ROLE[];
    }>("/api/auth/v2/user-permissions?service=SPNG");
    data.functionsIncludes.reduce((prv, cur) => {
      switch (cur.sourceType) {
        case "menu":
          prv.menus.push(cur.source);
          break;
        case "feature":
          prv.features.push(cur.source as FEATURE_KEY);
          break;
        default:
          break;
      }
      return prv;
    }, result);
    const { roleNames } = data;
    roleNames.sort((a, b) => {
      return (ROLE_PRIORITY?.[a] ?? 5000) - (ROLE_PRIORITY?.[b] ?? 5000);
    });
    result.role = roleNames?.[0] ?? ROLE.CISCO_WIDE_ROLE;

    return result;
  } catch (e) {
    console.error(e.response ?? e);
    return e.response?.status ?? null;
  }
};

const getFieldForInvoiceArrCustom = (
  setCollectionColumns?: Dispatch<SetStateAction<string[]>>
) => {
  let result = "";
  setCollectionColumns?.((prv) => {
    result = prv.filter((p) => p !== "invoiceArrContactCenterCloud").join(",");
    return prv;
  });
  return `sum(${result})`;
};

export const transformAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>,
  initialFilters: any = [],
  setCollectionColumns?: Dispatch<SetStateAction<string[]>>
) => {
  let result: {
    searchEnable?: boolean;
    keyword?: string;
    sorts?: any;
    filters?: any;
    viewType?: string;
  } = {};
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  let searchEnable = false;
  let keyword = "";
  let viewType = "my";

  // create type for filterModel and sortModel
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  const sortModel: { colId: string; sort: string }[] = sortModelReq?.map(
    (x: { colId: string; sort: string }) => ({
      colId:
        x.colId in SORT_KEY_MAPPING
          ? SORT_KEY_MAPPING[x.colId as keyof typeof SORT_KEY_MAPPING]
          : x.colId,
      sort: x.sort,
    })
  );
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      customizedParam?: any;
      savedStatus?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in FILTER_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [FILTER_KEY_MAPPING[k as keyof typeof FILTER_KEY_MAPPING]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }

  // transform sort
  if (sortModel?.length > 0) {
    const sorts = sortModel.map((i) => ({
      field:
        i.colId === "invoiceArrCustom"
          ? getFieldForInvoiceArrCustom(setCollectionColumns)
          : i.colId,
      sort: i.sort,
    }));
    result = { ...result, sorts };
  }

  const filterKeys = Object.keys(filterModel);
  const renewalRateList = [
    "mtgRenewedSub",
    "calRenewedSub",
    "mtgPendingSub",
    "callPendingSub",
  ];
  // transform filter
  const filters: {
    field: string;
    operator: string;
    value:
      | string
      | number
      | string[]
      | boolean
      | { [key: string]: string[] }
      | null;
  }[] = initialFilters;
  if (filterKeys.length > 0) {
    filterKeys.forEach((field) => {
      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalFilter") {
            switch (filterModel.externalFilter.filter) {
              case "Top 150":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 151,
                });
                break;
              case "Top 500":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 501,
                });
                break;
              // case "Top 500 w/o resources":
              //   filters.push(
              //     {
              //       field: "priorityIndicatorRank",
              //       operator: KEYWORD_MAPPING.lessThan,
              //       value: 501,
              //     },
              //     { field: "resource", operator: "eq", value: 0 }
              //   );
              //   break;
              // case "Regular w/ resources":
              //   filters.push({ field: "resource", operator: "eq", value: 1 });
              //   break;
              case "New Accounts":
                filters.push({
                  field: "newAccount",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
              // don't need it
              // case "My Accounts":
              //   filters.push({
              //     field: "myAccount",
              //     operator: KEYWORD_MAPPING.equals,
              //     value: 1,
              //   });
              //   break;
              case "Favorites":
                filters.push({
                  field: "favorites",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                viewType = "favorite";
                break;
              case "Zero Renewals":
                filters.push({
                  field: "zeroRenewalTerm",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
              case "Webex Suite":
                filters.push({
                  field: "webexSuiteFlag",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
              // for executive dashboard
              case "Top Exec Accounts":
                viewType = "all";
                break;
              case "My Accounts":
                viewType = "my";
                break;
              case "Renewal Alerts":
                filters.push({
                  field: "daysToRenewal",
                  operator: KEYWORD_MAPPING.lessThanOrEqual,
                  value: 180,
                });
                viewType = "all";
                break;
              case "Active Host Alerts":
                filters.push({
                  field: "registeredHostRatio",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 50,
                });
                viewType = "all";
                break;
              case "Support Alerts":
                filters.push({
                  field: "numOfTACTicketsOpen",
                  operator: KEYWORD_MAPPING.greaterThan,
                  value: 0,
                });
                viewType = "all";
                break;
            }
          } else if (field === "externalSearch") {
            // criteria from search bar, eg: true%|%apple
            const [
              include,
              // _column,
              ...values
            ] = filterModel.externalSearch.filter.split("%|%");
            // if empty will have value [""]
            if (!!values[0]) {
              // filters.push({
              //   field: column,
              //   operator: KEYWORD_MAPPING.contains,
              //   value: values.join("%|%"),
              // });
              searchEnable = true;
              keyword = values.join("%|%");
              if (viewType !== "favorite") viewType = keyword ? "all" : "my";
            }
            if (include === "true") {
              filters.push({
                field: "includeInactive",
                operator: KEYWORD_MAPPING.equals,
                value: 1,
              });
            }
          } else if (field === "externalQuery") {
            const queries = filterModel.externalQuery.filter.split("%-%");
            queries.forEach((query) => {
              const values = query.split("%|%");
              if (values?.[0] !== "viewType")
                filters.push({
                  field: values?.[0] || "",
                  operator: values?.[1] || "",
                  value: values?.[2] || "",
                });
              searchEnable =
                values?.[0] === "viewType" && values?.[2] === "all";
            });
          } else if (field === "externalAlertFilter") {
            switch (filterModel.externalAlertFilter?.filter) {
              case "Auto Renew Off/Pending Cancel":
                filters.push({
                  field: "arOffPCAlert",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
              case "Usage Alerts":
                filters.push({
                  field: "alertType",
                  operator: KEYWORD_MAPPING.equals,
                  value: "USAGE",
                });
                break;
              case "Churn Risk Alerts":
                filters.push({
                  field: "alertType",
                  operator: KEYWORD_MAPPING.equals,
                  value: "CHURN_RISK",
                });
                break;
              case "Support Alerts":
                filters.push({
                  field: "alertType",
                  operator: KEYWORD_MAPPING.equals,
                  value: "SUPPORT",
                });
                break;
              case "Health Alerts":
                filters.push({
                  field: "alertType",
                  operator: KEYWORD_MAPPING.equals,
                  value: "HEALTH",
                });
                break;
              case "AMER/EMEA":
                filters.push({
                  field: "epRequiredAMER",
                  operator: KEYWORD_MAPPING.equals,
                  value: "y",
                });
                break;
              case "APJC":
                filters.push({
                  field: "epRequiredAPJC",
                  operator: KEYWORD_MAPPING.equals,
                  value: "y",
                });
                break;
              // case "Engagement Model Alerts":
              //   filters.push({
              //     field: "alertType",
              //     operator: KEYWORD_MAPPING.equals,
              //     value: "AEM_WEM",
              //   });
              //   break;
            }
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.contains,
              value: filterModel[field].filter,
            });
          }
          break;
        case "in":
          if (field === "partnerFilter") {
            const filterValue: { [key: string]: string[] } = {};
            filterModel[field].filter.split("|").forEach((item) => {
              const [type, ...value] = item.split("%-%");
              filterValue[type] = (filterValue[type] || []).concat([
                value.join(""),
              ]);
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else if (
            field === "fiscalRenewalMonth" ||
            field === "productServices"
          ) {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              const [_type, ...value] = item.split("%-%");
              filterValue.push(value.join(""));
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else if (field === "bpp") {
            const filterValue: string[] = [];
            if (!filterModel[field].filter) {
              filters.push({
                field: "bpp",
                operator:
                  filterModel[field]?.savedStatus?.yesOrNo === "yes"
                    ? "gt"
                    : "eq",
                value: 0,
              });
            } else {
              filterModel[field].filter.split("|").forEach((item) => {
                if (item !== "") {
                  filterValue.push(item);
                }
              });
              filters.push({
                field: "betaProgram",
                operator: KEYWORD_MAPPING.in,
                value: filterValue,
              });
            }
          } else if (field === "reseller" || field === "resellerId") {
            filters.push({
              field: field,
              operator: KEYWORD_MAPPING.in,
              value: [...filterModel[field].filter.split("|")],
            });
          } else if (field === "lifecycleStageByWorkload") {
            const itemArray = filterModel[field].filter.split("|");
            const filterValue = itemArray.reduce((prev, curr) => {
              const kv = curr.split(" - ");
              if (kv && kv[0] in prev) {
                prev[kv[0]].push(kv[1]);
              } else {
                prev[kv[0]] = [kv[1]];
              }
              return prev;
            }, {} as { [key: string]: string[] });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          }

          break;
        case "between":
          if (field.includes("adoption")) {
            const { from, to, numberOrPercent } = filterModel[
              field
            ].savedStatus;
            filters.push({
              field: numberOrPercent === "percent" ? field + "Per" : field,
              operator: KEYWORD_MAPPING.greaterThanOrEqual,
              value: from,
            });
            filters.push({
              field: numberOrPercent === "percent" ? field + "Per" : field,
              operator: KEYWORD_MAPPING.lessThanOrEqual,
              value: to,
            });
          } else {
            const [startDate, endDate] = filterModel[field].filter.split("|");
            filters.push({
              field:
                field === "invoiceArrCustom"
                  ? getFieldForInvoiceArrCustom(setCollectionColumns)
                  : field,
              operator: KEYWORD_MAPPING.greaterThanOrEqual,
              value: startDate,
            });
            filters.push({
              field:
                field === "invoiceArrCustom"
                  ? getFieldForInvoiceArrCustom(setCollectionColumns)
                  : field,
              operator: KEYWORD_MAPPING.lessThanOrEqual,
              value: endDate,
            });
          }
          break;
        case "notIn":
          if (field === "reseller" || field === "resellerId") {
            filters.push({
              field: field,
              operator: KEYWORD_MAPPING.notIn,
              value: [...filterModel[field].filter.split("|")],
            });
          }
          break;
        case "equals":
          if (field === "sites") {
            filters.push({
              field: "siteExists",
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          }
          const { userInputDateString, userInputDate } =
            filterModel[field]?.customizedParam ?? {};
          if (field === "expandPlanExists" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
          } else if (
            field === "partnerExpandPlanExists" &&
            userInputDateString
          ) {
            if (userInputDateString.indexOf("|") > -1) {
              const partnerExpandPlanDates = userInputDateString.split("|");
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: partnerExpandPlanDates[0] ?? "",
              });
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: partnerExpandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
            filters.push({
              field,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          } else if (field === "collabMapUploadedDate" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const collabMapUploadedDates = userInputDateString.split("|");
              filters.push({
                field: "collabMapUploadedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: collabMapUploadedDates[0] ?? "",
              });
              filters.push({
                field: "collabMapUploadedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: collabMapUploadedDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "collabMapUploadedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? null,
              });
            }
          } else if (
            field === "collabMapUploadedDate" &&
            filterModel[field].filter === "0"
          ) {
            filters.push({
              field: "collabMapUploadedDate",
              operator: KEYWORD_MAPPING.equals,
              value: null,
            });
          } else if (field === "conciergeStatus" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const conciergeDates = userInputDateString.split("|");
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: conciergeDates[0] ?? "",
              });
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: conciergeDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: userInputDateString
                  ? userInputDateString
                  : userInputDate.toString(),
              });
            }
            filters.push({
              field,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          } else if (renewalRateList.includes(field)) {
            filters.push({
              field,
              operator:
                filterModel[field].filter === "Y"
                  ? KEYWORD_MAPPING.greaterThan
                  : KEYWORD_MAPPING.equals,
              value: 0,
            });
          } else if (field.includes("ExpReason")) {
            filters.push({
              field,
              operator:
                filterModel[field].filter === "null"
                  ? KEYWORD_MAPPING.equals
                  : KEYWORD_MAPPING.notEquals,
              value: null,
            });
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          }
          break;
        case "notEquals":
          if (field === "collabMapUploadedDate") {
            filters.push({
              field: "collabMapUploadedDate",
              operator: KEYWORD_MAPPING.notEquals,
              value: null,
            });
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.notEquals,
              value: filterModel[field].filter,
            });
          }
          break;
        case "isNetNew":
          filters.push({
            field: "isNetNew",
            operator: "eq",
            value: 1,
          });
          break;
        default:
          if (field !== "collabMapUploadedDate") {
            if (field === "invoiceArrCustom") {
              filters.push({
                field: getFieldForInvoiceArrCustom(setCollectionColumns),
                operator: KEYWORD_MAPPING[filterModel[field].type],
                value: filterModel[field].filter,
              });
            } else {
              if (field.includes("adoption")) {
                const { from, numberOrPercent } = filterModel[
                  field
                ].savedStatus;
                filters.push({
                  field: numberOrPercent === "percent" ? field + "Per" : field,
                  operator: KEYWORD_MAPPING[filterModel[field].type],
                  value: from,
                });
              } else {
                filters.push({
                  field,
                  operator: KEYWORD_MAPPING[filterModel[field].type],
                  value: filterModel[field].filter,
                });
              }
            }
          }
          break;
      }
    });
  }

  return {
    ...result,
    filters,
    searchEnable,
    keyword,
    viewType,
  };
};

export const transformExecutiveAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>,
  initialFilters: any = [],
  setCollectionColumns?: Dispatch<SetStateAction<string[]>>
) => {
  let result: {
    searchEnable?: boolean;
    keyword?: string;
    sorts?: any;
    filters?: any;
    viewType?: string;
  } = {};
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  let searchEnable = false;
  let keyword = "";
  let viewType = "all";
  let topCategory = "";
  let quickFilterType = "";

  // create type for filterModel and sortModel
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  const sortModel: { colId: string; sort: string }[] = sortModelReq?.map(
    (x: { colId: string; sort: string }) => ({
      colId:
        x.colId in SORT_KEY_MAPPING
          ? SORT_KEY_MAPPING[x.colId as keyof typeof SORT_KEY_MAPPING]
          : x.colId,
      sort: x.sort,
    })
  );
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      customizedParam?: any;
      savedStatus?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in FILTER_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [FILTER_KEY_MAPPING[k as keyof typeof FILTER_KEY_MAPPING]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }

  // transform sort
  if (sortModel?.length > 0) {
    const sorts = sortModel.map((i) => ({
      field:
        i.colId === "invoiceArrCustom"
          ? getFieldForInvoiceArrCustom(setCollectionColumns)
          : i.colId,
      sort: i.sort,
    }));
    result = { ...result, sorts };
  }
  const filterKeys = Object.keys(filterModel).filter(
    (k) => k !== "topCategoryFilter"
  );
  const renewalRateList = [
    "mtgRenewedSub",
    "calRenewedSub",
    "mtgPendingSub",
    "callPendingSub",
  ];
  // transform filter
  const filters: {
    field: string;
    operator: string;
    value:
      | string
      | number
      | string[]
      | boolean
      | { [key: string]: string[] }
      | null;
  }[] = initialFilters;
  if (filterKeys.length > 0) {
    filterKeys.forEach((field) => {
      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalFilter") {
            quickFilterType = filterModel.externalFilter.filter;
            topCategory = filterModel?.topCategoryFilter?.filter ?? "all";
            switch (filterModel.externalFilter.filter) {
              case "Webex Suite":
                filters.push({
                  field: "webexSuiteFlag",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
              // for executive dashboard
              case "Top Exec Accounts":
                viewType = "all";
                break;
              case "My Accounts":
                const externalSearch = filterModel.externalSearch?.filter ?? "";
                const arr = externalSearch.split("%|%");
                viewType = arr.length == 2 && arr[1] ? "all" : "my";
                break;
              case "Renewal Alerts":
                filters.push({
                  field: "daysToRenewal",
                  operator: KEYWORD_MAPPING.lessThanOrEqual,
                  value: 180,
                });
                viewType = "all";
                break;
              case "Active Host Alerts":
                filters.push({
                  field: "registeredHostRatio",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 50,
                });
                viewType = "all";
                break;
              case "Support Alerts":
                filters.push({
                  field: "supportAlerts",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                viewType = "all";
                break;
              case "Collab CSM Overall Risk Assessment":
                filters.push({
                  field: "riskChangedAlerts",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                viewType = "all";
                break;
              case "AMER/EMEA":
                filters.push({
                  field: "epRequiredAMER",
                  operator: KEYWORD_MAPPING.equals,
                  value: "y",
                });
                viewType = "all";
                break;
              case "APJC":
                filters.push({
                  field: "epRequiredAPJC",
                  operator: KEYWORD_MAPPING.equals,
                  value: "y",
                });
                viewType = "all";
                break;
            }
          } else if (field === "externalSearch") {
            // criteria from search bar, eg: true%|%apple
            const [
              include,
              // _column,
              ...values
            ] = filterModel.externalSearch.filter.split("%|%");
            // if empty will have value [""]
            if (!!values[0]) {
              // filters.push({
              //   field: column,
              //   operator: KEYWORD_MAPPING.contains,
              //   value: values.join("%|%"),
              // });
              searchEnable = true;
              keyword = values.join("%|%");
              if (viewType !== "favorite") viewType = keyword ? "all" : "my";
            }
            if (include === "true") {
              filters.push({
                field: "includeInactive",
                operator: KEYWORD_MAPPING.equals,
                value: 1,
              });
            }
          } else if (field === "externalQuery") {
            const queries = filterModel.externalQuery.filter.split("%-%");
            queries.forEach((query) => {
              const values = query.split("%|%");
              if (values?.[0] !== "viewType")
                filters.push({
                  field: values?.[0] || "",
                  operator: values?.[1] || "",
                  value: values?.[2] || "",
                });
              searchEnable =
                values?.[0] === "viewType" && values?.[2] === "all";
            });
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.contains,
              value: filterModel[field].filter,
            });
          }
          break;
        case "in":
          if (field === "partnerFilter") {
            const filterValue: { [key: string]: string[] } = {};
            filterModel[field].filter.split("|").forEach((item) => {
              const [type, ...value] = item.split("%-%");
              filterValue[type] = (filterValue[type] || []).concat([
                value.join(""),
              ]);
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else if (
            field === "fiscalRenewalMonth" ||
            field === "productServices"
          ) {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              const [_type, ...value] = item.split("%-%");
              filterValue.push(value.join(""));
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else if (field === "bpp") {
            const filterValue: string[] = [];
            if (!filterModel[field].filter) {
              filters.push({
                field: "bpp",
                operator:
                  filterModel[field]?.savedStatus?.yesOrNo === "yes"
                    ? "gt"
                    : "eq",
                value: 0,
              });
            } else {
              filterModel[field].filter.split("|").forEach((item) => {
                if (item !== "") {
                  filterValue.push(item);
                }
              });
              filters.push({
                field: "betaProgram",
                operator: KEYWORD_MAPPING.in,
                value: filterValue,
              });
            }
          } else if (field === "lifecycleStageByWorkload") {
            const itemArray = filterModel[field].filter.split("|");
            const filterValue = itemArray.reduce((prev, curr) => {
              const kv = curr.split(" - ");
              if (kv && kv[0] in prev) {
                prev[kv[0]].push(kv[1]);
              } else {
                prev[kv[0]] = [kv[1]];
              }
              return prev;
            }, {} as { [key: string]: string[] });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          }

          break;
        case "between":
          if (field.includes("adoption")) {
            const { from, to, numberOrPercent } = filterModel[
              field
            ].savedStatus;
            filters.push({
              field: numberOrPercent === "percent" ? field + "Per" : field,
              operator: KEYWORD_MAPPING.greaterThanOrEqual,
              value: from,
            });
            filters.push({
              field: numberOrPercent === "percent" ? field + "Per" : field,
              operator: KEYWORD_MAPPING.lessThanOrEqual,
              value: to,
            });
          } else {
            const [startDate, endDate] = filterModel[field].filter.split("|");
            filters.push({
              field:
                field === "invoiceArrCustom"
                  ? getFieldForInvoiceArrCustom(setCollectionColumns)
                  : field,
              operator: KEYWORD_MAPPING.greaterThanOrEqual,
              value: startDate,
            });
            filters.push({
              field:
                field === "invoiceArrCustom"
                  ? getFieldForInvoiceArrCustom(setCollectionColumns)
                  : field,
              operator: KEYWORD_MAPPING.lessThanOrEqual,
              value: endDate,
            });
          }
          break;
        case "equals":
          if (field === "sites") {
            filters.push({
              field: "siteExists",
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          }
          const { userInputDateString, userInputDate } =
            filterModel[field]?.customizedParam ?? {};
          if (field === "expandPlanExists" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
          } else if (
            field === "partnerExpandPlanExists" &&
            userInputDateString
          ) {
            if (userInputDateString.indexOf("|") > -1) {
              const partnerExpandPlanDates = userInputDateString.split("|");
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: partnerExpandPlanDates[0] ?? "",
              });
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: partnerExpandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
            filters.push({
              field,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          } else if (field === "collabMapUploadedDate" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const collabMapUploadedDates = userInputDateString.split("|");
              filters.push({
                field: "collabMapUploadedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: collabMapUploadedDates[0] ?? "",
              });
              filters.push({
                field: "collabMapUploadedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: collabMapUploadedDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "collabMapUploadedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? null,
              });
            }
          } else if (
            field === "collabMapUploadedDate" &&
            filterModel[field].filter === "0"
          ) {
            filters.push({
              field: "collabMapUploadedDate",
              operator: KEYWORD_MAPPING.equals,
              value: null,
            });
          } else if (field === "conciergeStatus" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const conciergeDates = userInputDateString.split("|");
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: conciergeDates[0] ?? "",
              });
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: conciergeDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: userInputDateString
                  ? userInputDateString
                  : userInputDate.toString(),
              });
            }
            filters.push({
              field,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          } else if (renewalRateList.includes(field)) {
            filters.push({
              field,
              operator:
                filterModel[field].filter === "Y"
                  ? KEYWORD_MAPPING.greaterThan
                  : KEYWORD_MAPPING.equals,
              value: 0,
            });
          } else if (field.includes("ExpReason")) {
            filters.push({
              field,
              operator:
                filterModel[field].filter === "null"
                  ? KEYWORD_MAPPING.equals
                  : KEYWORD_MAPPING.notEquals,
              value: null,
            });
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          }
          break;
        case "notEquals":
          if (field === "collabMapUploadedDate") {
            filters.push({
              field: "collabMapUploadedDate",
              operator: KEYWORD_MAPPING.notEquals,
              value: null,
            });
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.notEquals,
              value: filterModel[field].filter,
            });
          }
          break;
        case "isNetNew":
          filters.push({
            field: "isNetNew",
            operator: "eq",
            value: 1,
          });
          break;
        default:
          if (field !== "collabMapUploadedDate") {
            if (field === "invoiceArrCustom") {
              filters.push({
                field: getFieldForInvoiceArrCustom(setCollectionColumns),
                operator: KEYWORD_MAPPING[filterModel[field].type],
                value: filterModel[field].filter,
              });
            } else {
              if (field.includes("adoption")) {
                const { from, numberOrPercent } = filterModel[
                  field
                ].savedStatus;
                filters.push({
                  field: numberOrPercent === "percent" ? field + "Per" : field,
                  operator: KEYWORD_MAPPING[filterModel[field].type],
                  value: from,
                });
              } else {
                filters.push({
                  field,
                  operator: KEYWORD_MAPPING[filterModel[field].type],
                  value: filterModel[field].filter,
                });
              }
            }
          }
          break;
      }
    });
  }

  if (topCategory.trim() !== "" && topCategory.trim() !== "all") {
    return {
      ...result,
      filters,
      searchEnable,
      keyword,
      viewType,
      [topCategory]: true,
    };
  } else {
    return {
      ...result,
      filters,
      searchEnable,
      keyword,
      viewType,
    };
  }
};

export const transformBody = (body: {
  sorts?: { field: string; sort: string }[];
  filters?: { field: string; operator: string; value: string }[];
}) => {
  let params = {};
  const { sorts, filters } = body;
  if (filters) {
    params = filters.reduce((prv, cur) => {
      return { ...prv, [cur.field]: `${cur.operator}:${cur.value}` };
    }, {});
  }
  if (sorts) {
    const sortStr = sorts.reduce((prv, cur, i) => {
      return i === 0
        ? `${cur.field}:${cur.sort}`
        : `${prv},${cur.field}:${cur.sort}`;
    }, "");
    params = { ...params, sort: sortStr };
  }
  return params;
};

export const transformEnterpriseDashboardAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>
) => {
  let result: {
    sorts?: any;
    filters?: any;
    sortBySite?: any;
    searchEnable?: boolean;
    keyword?: string;
  } = {};
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  let searchEnable = false;
  let keyword = "";
  // create type for filterModel and sortModel
  const sortModel: { colId: string; sort: string }[] = sortModelReq;
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      filterValue?: string | boolean;
      customizedParam?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in DASHBOARD_ENTERPRISE_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [DASHBOARD_ENTERPRISE_KEY_MAPPING[
          k as keyof typeof DASHBOARD_ENTERPRISE_KEY_MAPPING
        ]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }
  // transform sort
  if (sortModel?.length > 0) {
    const sort = sortModel
      .filter((item) => item.colId.split("-").length === 1)
      .map((i) => ({ field: i.colId, sort: i.sort }));
    if (sort?.length) {
      result = { ...result, sorts: sort };
    }

    // site related column
    const sortBySite = sortModel
      .filter((item) => item.colId.split("-").length === 2)
      .map((i) => ({ field: i.colId?.split("-")?.[1], sort: i.sort }));
    if (sortBySite?.length) {
      result = { ...result, sorts: sortBySite };
    }
  }
  const filterKeys = Object.keys(filterModel);
  // let filters: any = {};
  const filters: {
    field: string;
    operator: string;
    value:
      | string
      | number
      | string[]
      | boolean
      | { [key: string]: string[] }
      | null;
  }[] = [];
  if (!filterKeys.includes("externalToggle")) {
    filters.push({
      field: "primarySite",
      operator: "eq",
      value: "true",
    });
  }

  if (!filterKeys.includes("externalInactiveSite")) {
    filters.push({
      field: "activeSite",
      operator: "eq",
      value: "true",
    });
  }
  // filters.push({
  //   field: "loadBPP",
  //   operator: "eq",
  //   value: "true",
  // });
  // transform filter
  if (filterKeys.length > 0) {
    // const filters: {
    //   field: string;
    //   operator: string;
    //   value: string | number | string[] | boolean;
    // }[] = [];
    filterKeys.forEach((field) => {
      const defaultKey = field.split("-")[1] ?? field;

      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalFilter") {
            switch (filterModel.externalFilter.filter) {
              case "TOP 150":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 151,
                });
                break;
              case "TOP 500":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 501,
                });
                break;
              default:
                break;
            }
          } else if (field === "externalToggle") {
            if (filterModel.externalToggle.filter === "true") {
              filters.push({
                field: "primarySite",
                operator: "eq",
                value: "true",
              });
            }
          } else if (field === "externalInactiveSite") {
            if (filterModel.externalInactiveSite.filter === "true") {
              filters.push({
                field: "activeSite",
                operator: "eq",
                value: "true",
              });
            }
          } else if (field === "externalKeyword") {
          } else if (field === "externalSearch") {
            const [
              _column,
              ...values
            ] = filterModel.externalSearch.filter.split("%|%");
            if (values.length && !!values[0]) {
              keyword = values.join("");
              searchEnable = true;
            }
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.contains,
              value: filterModel[field].filter,
            });
          }
          break;
        case "in":
          if (field === "partnerFilter") {
            const filterValue: { [key: string]: string[] } = {};
            filterModel[field].filter.split("|").forEach((item) => {
              const [type, ...value] = item.split("%-%");
              filterValue[type] = (filterValue[type] || []).concat([
                value.join(""),
              ]);
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            filters.push({
              field: defaultKey,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          }
          break;
        case "equals":
          const { userInputDateString, userInputDate } =
            filterModel[field]?.customizedParam ?? {};
          if (defaultKey === "hybCal") {
            switch (filterModel[field].filter) {
              case "Exchange":
                filters.push({
                  field: "hybCalExch",
                  operator: "eq",
                  value: "y",
                });
                break;
              case "Google":
                filters.push({
                  field: "hybCalGg",
                  operator: "eq",
                  value: "y",
                });
                break;
              case "Office 365":
                filters.push({
                  field: "hybCalO365",
                  operator: "eq",
                  value: "y",
                });
                break;
              case "Not Enabled":
                const mutiCols = ["hybCalExch", "hybCalGg", "hybCalO365"];
                mutiCols.map((col) => {
                  filters.push({
                    field: col,
                    operator: "eq",
                    value: "n",
                  });
                });
                break;
              default:
            }
          } else if (field === "conciergeStatus" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const conciergeDates = userInputDateString.split("|");
              filters.push({
                field: "conciergeStatus",
                operator: "eq",
                value: "Active",
              });
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: conciergeDates[0] ?? "",
              });
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: conciergeDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "conciergeStatus",
                operator: "eq",
                value: "Active",
              });
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: userInputDateString
                  ? userInputDateString
                  : userInputDate.toString(),
              });
            }
          } else {
            filters.push({
              field: defaultKey,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          }
          break;
        default:
          filters.push({
            field,
            operator: KEYWORD_MAPPING[filterModel[field].type],
            value: filterModel[field].filter,
          });
      }
    });
  }
  return {
    ...result,
    filters,
    searchEnable,
    keyword: keyword ? keyword : undefined,
  };
};

export const transformRenewalsDashboardAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>,
  setCollectionColumns: React.Dispatch<React.SetStateAction<string[]>>
) => {
  let result: {
    sort?: any;
    filters?: any;
    sortBySite?: any;
    searchEnable?: boolean;
  } = {};
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  // const { filterModel: filterModelReq } = req;
  let searchEnable = false;
  let includeInactive = false;
  let includeInactiveAccounts = false;
  // create type for filterModel and sortModel
  const sortModel: { colId: string; sort: string }[] = sortModelReq;
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      filterValue?: string | boolean;
      customizedParam?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in DASHBOARD_FORECAST_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [DASHBOARD_FORECAST_KEY_MAPPING[
          k as keyof typeof DASHBOARD_FORECAST_KEY_MAPPING
        ]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }
  // transform sort
  if (sortModel?.length > 0) {
    const sortStr = sortModel
      .map((x: { colId: string; sort: string }) => ({
        colId:
          x.colId in DASHBOARD_RENEWALS_SORT_KEY_MAPPING
            ? DASHBOARD_RENEWALS_SORT_KEY_MAPPING[
                x.colId as keyof typeof DASHBOARD_RENEWALS_SORT_KEY_MAPPING
              ]
            : x.colId === "invoiceArrCustom"
            ? getFieldForInvoiceArrCustom(setCollectionColumns)
            : x.colId,
        sort: x.sort,
      }))
      .map((x: { colId: string; sort: string }) => x.colId + ":" + x.sort);
    if (sortStr) {
      result = { ...result, sort: sortStr[0] };
    }
  }
  const filterKeys = Object.keys(filterModel);
  let filters: any = {};
  // transform filter
  if (filterKeys.length > 0) {
    filterKeys.forEach((field) => {
      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalFilter") {
            if (filterModel.externalFilter.filter.includes("*")) {
              const [
                filterName,
                ...filterVal
              ] = filterModel.externalFilter.filter.split("*");
              filters = {
                ...filters,
                [filterName]: filterVal[0],
              };
            }
            switch (filterModel.externalFilter.filter) {
              case "TOP 150":
                filters = {
                  ...filters,
                  priorityIndicatorRank: `${KEYWORD_MAPPING.lessThan}:151`,
                };
                break;
              case "TOP 500":
                filters = {
                  ...filters,
                  priorityIndicatorRank: `${KEYWORD_MAPPING.lessThan}:501`,
                };
                break;
              case "Favorite":
                filters = {
                  ...filters,
                  favorite: true,
                };
                break;
            }
          } else if (field === "externalSearch") {
            const [
              _includeInactive,
              ...values
            ] = filterModel.externalSearch.filter.split("%|%");
            if (values.length && !!values[0]) {
              filters = {
                ...filters,
                keyword: values.join("") || "",
              };
              searchEnable = true;
            }
          } else if (field === "externalIncludeInactiveSubscription") {
            includeInactive = filterModel[field].filter === "true";
            includeInactiveAccounts =
              includeInactive &&
              !!filterModel?.["externalSearch"]?.filter?.split("%|%")?.[1];
          } else if (field === "externalKeyword") {
          } else if (field === "externalEPFilter") {
            switch (filterModel.externalEPFilter.filter) {
              case "AMER/EMEA":
                filters = {
                  ...filters,
                  epRequiredAMER: "y",
                };
                break;
              case "APJC":
                filters = {
                  ...filters,
                  epRequiredAPJC: "y",
                };
                break;
            }
          } else {
            const value = `${KEYWORD_MAPPING.contains}:${filterModel[field].filter}`;
            if (field in filters) {
              if (Array.isArray(filters[field])) {
                filters[field].push(value);
              } else {
                filters = {
                  ...filters,
                  [field]: [filters[field], value],
                };
              }
            } else {
              filters = {
                ...filters,
                [field]: value,
              };
            }
            if (field === "ciOrgId") {
              filters = {
                ...filters,
                [field]: value,
              };
            }
            // filters = {
            //   ...filters,
            //   [field]: `${KEYWORD_MAPPING.contains}:${filterModel[field].filter}`,
            // };
          }
          break;
        case "in":
          if (field === "arpc") {
            // split into 2 fields to call api: arFlag, pcStatus
            const filterVal = filterModel[field].filter.split("|");
            if (filterVal.every((item) => item == "0" || item == "1")) {
              filters = {
                ...filters,
                arFlag: `${KEYWORD_MAPPING.in}:${filterVal.join(",")}`,
              };
            } else if (filterVal.every((item) => item != "0" && item != "1")) {
              filters = {
                ...filters,
                pcStatus: `${KEYWORD_MAPPING.in}:${filterVal.join(",")}`,
              };
            } else {
              const arFlagArr = filterVal.filter(
                (item) => item == "0" || item == "1"
              );
              const pcStatusArr = filterVal.filter(
                (item) => item != "0" && item != "1"
              );
              filters = {
                ...filters,
                arFlag: `${KEYWORD_MAPPING.in}:${arFlagArr?.join(",")}`,
                pcStatus: `${KEYWORD_MAPPING.in}:${pcStatusArr?.join(",")}`,
              };
            }
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                if (item === "Not Forecasted") {
                  filterValue.push("null");
                } else {
                  filterValue.push(item);
                }
              }
            });
            if (
              field === "renewalFactors" ||
              field === "managementTags" ||
              field === "personalTags" ||
              field === "riskAssessment" ||
              field === "forecasting" ||
              field === "subRiskAssessment"
            ) {
              filters = {
                ...filters,
                [field]: `${filterValue}`,
              };
            } else if (field === "fiscalRenewalMonth") {
              const filterVal: string[] = [];
              filterValue.forEach((item) => {
                const [_type, ...value] = item.split("%-%");
                filterVal.push(value.join(""));
              });
              filters = {
                ...filters,
                [field]: `${KEYWORD_MAPPING.in}:${filterVal}`,
              };
            } else {
              filters = {
                ...filters,
                [field]: `${KEYWORD_MAPPING.in}:${filterValue}`,
              };
            }
          }
          break;
        case "equals":
          const { userInputDateString, userInputDate } =
            filterModel[field]?.customizedParam ?? {};
          if (field == "epLastModifiedTime") {
            filters = {
              ...filters,
              expandPlanExists: filterModel[field].filter == "1" ? "y" : "n",
            };
            const { userInputDateString } =
              filterModel[field]?.customizedParam ?? {};
            if (userInputDateString) {
              if (userInputDateString.indexOf("|") > -1) {
                const expandPlanDates = userInputDateString.split("|");
                filters = {
                  ...filters,
                  startCiscoExpandPlanCreatedDate: expandPlanDates[0],
                  endCiscoExpandPlanCreatedDate: expandPlanDates[1],
                };
              } else {
                filters = {
                  ...filters,
                  startCiscoExpandPlanCreatedDate: userInputDateString,
                };
              }
            }
          } else if (field === "conciergeStatus" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const conciergeDates = userInputDateString.split("|");
              filters = {
                ...filters,
                conciergeStatus: "Active",
                conciergeEffectiveTo: [
                  `${KEYWORD_MAPPING.lessThanOrEqual}:${
                    conciergeDates[1] ?? ""
                  }`,
                  `${KEYWORD_MAPPING.greaterThanOrEqual}:${
                    conciergeDates[0] ?? ""
                  }`,
                ],
              };
            } else {
              filters = {
                ...filters,
                conciergeStatus: "Active",
                // conciergeEffectiveFrom: `${
                //   KEYWORD_MAPPING.greaterThanOrEqual
                // }:${userInputDateString ?? ""}`,
                conciergeEffectiveTo: `${KEYWORD_MAPPING.lessThanOrEqual}:${
                  userInputDateString
                    ? userInputDateString
                    : userInputDate.toString()
                }`,
              };
            }
          } else if (field.includes("RevenueRate")) {
            filters = {
              ...filters,
              [field]: (Number(filterModel[field].filter) / 100).toFixed(2),
            };
          } else if (field === "sites") {
            filters = {
              ...filters,
              siteExists: filterModel[field].filter,
            };
          } else if (field === "invoiceArrCustom") {
            filters = {
              ...filters,
              [getFieldForInvoiceArrCustom(
                setCollectionColumns
              )]: `${KEYWORD_MAPPING.equals}:${filterModel[field].filter}`,
            };
          } else {
            filters = {
              ...filters,
              [field]: filterModel[field].filter,
            };
          }
          break;
        case "lessThan":
          if (field.includes("RevenueRate")) {
            filters = {
              ...filters,
              [field]: `${KEYWORD_MAPPING.lessThan}:${(
                Number(filterModel[field].filter) / 100
              ).toFixed(2)}`,
            };
          } else if (field === "invoiceArrCustom") {
            filters = {
              ...filters,
              [getFieldForInvoiceArrCustom(
                setCollectionColumns
              )]: `${KEYWORD_MAPPING.lessThan}:${filterModel[field].filter}`,
            };
          } else {
            filters = {
              ...filters,
              [field]: `${KEYWORD_MAPPING.lessThan}:${filterModel[field].filter}`,
            };
          }
          break;
        case "greaterThan":
          if (field.includes("RevenueRate")) {
            filters = {
              ...filters,
              [field]: `${KEYWORD_MAPPING.greaterThan}:${(
                Number(filterModel[field].filter) / 100
              ).toFixed(2)}`,
            };
          } else if (field === "invoiceArrCustom") {
            filters = {
              ...filters,
              [getFieldForInvoiceArrCustom(
                setCollectionColumns
              )]: `${KEYWORD_MAPPING.greaterThan}:${filterModel[field].filter}`,
            };
          } else {
            filters = {
              ...filters,
              [field]: `${KEYWORD_MAPPING.greaterThan}:${filterModel[field].filter}`,
            };
          }
          break;
        case "between":
          filters = {
            ...filters,
            [field]: `${filterModel[field].filter}`,
          };
          break;
        default:
          filters = {
            ...filters,
            [field]: `${KEYWORD_MAPPING[filterModel[field].type]}:${
              filterModel[field].filter
            }`,
          };
      }
    });
    result = { ...result, ...filters };
  }
  return { ...result, searchEnable, includeInactive, includeInactiveAccounts };
};

export const transformBenchmarkingDashboardAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>
) => {
  let result: {
    keyword?: string;
    searchEnable?: boolean;
    sorts?: any;
    filters?: any;
    segmentation?: any;
  } = {};
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  // create type for filterModel and sortModel
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  const sortModel: { colId: string; sort: string }[] = sortModelReq?.map(
    (x: { colId: string; sort: string }) => ({
      colId:
        x.colId in DASHBOARD_BENCHMARKING_SORT_KEY_MAPPING
          ? DASHBOARD_BENCHMARKING_SORT_KEY_MAPPING[
              x.colId as keyof typeof DASHBOARD_BENCHMARKING_SORT_KEY_MAPPING
            ]
          : x.colId,
      sort: x.sort,
    })
  );
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      customizedParam?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in DASHBOARD_BENCHMARKING_FILTER_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [DASHBOARD_BENCHMARKING_FILTER_KEY_MAPPING[
          k as keyof typeof DASHBOARD_BENCHMARKING_FILTER_KEY_MAPPING
        ]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }
  // transform sort
  if (sortModel?.length > 0) {
    const sorts = [] as any;
    sortModel.reduce((pre, cur) => {
      return pre.push({ field: cur?.colId?.split("-")?.[1], sort: cur.sort });
    }, sorts);
    if (sortModel) {
      result = { ...result, sorts };
    }
    // const sorts = sortModel.map((i) => ({ field: i.colId, sort: i.sort }));
    // result = { ...result, sorts };
  }
  const filterKeys = Object.keys(filterModel);
  // transform filter
  const filters: {
    field: string;
    operator: string;
    value: string | number | string[] | boolean | { [key: string]: string[] };
  }[] = [];
  const segmentation: {
    field: string;
    operator: string;
    value: string | number | string[] | boolean | { [key: string]: string[] };
  }[] = [];
  if (filterKeys.length > 0) {
    filterKeys.forEach((field) => {
      const defaultKey = field.split("-")[1] ?? field;
      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalFilter") {
            switch (filterModel.externalFilter.filter) {
              case "TOP 150":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 151,
                });
                break;
              case "TOP 500":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 501,
                });
                break;
              case "My Accounts":
                filters.push({
                  field: "myAccount",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
            }
          } else if (field === "externalSearch") {
            // criteria from search bar, eg: true%|%accountName%|%apple
            const [
              _column,
              ...values
            ] = filterModel.externalSearch.filter.split("%|%");
            // if empty will have value [""]
            if (!!values[0]) {
              result.keyword = values.join("%|%");
              // filters.push({
              //   field: "keyword",
              //   operator: KEYWORD_MAPPING.equals,
              //   value: values.join("%|%"),
              // });
            }
          } else if (field === "externalAlertFilter") {
            switch (filterModel.externalAlertFilter.filter) {
              case "Auto Renew Off/Pending Cancel":
                filters.push({
                  field: "arOffPCAlert",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
            }
          } else {
            filters.push({
              field: defaultKey,
              operator: KEYWORD_MAPPING.contains,
              value: filterModel[field].filter,
            });
          }
          break;
        case "in":
          if (field === "partnerFilter") {
            const filterValue: { [key: string]: string[] } = {};
            filterModel[field].filter.split("|").forEach((item) => {
              const [type, ...value] = item.split("%-%");
              filterValue[type] = (filterValue[type] || []).concat([
                value.join(""),
              ]);
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else if (field === "fiscalRenewalMonth") {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              const [_type, ...value] = item.split("%-%");
              filterValue.push(value.join(""));
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else if (field.startsWith("accountSegmentation")) {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            segmentation.push({
              field: defaultKey,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            filters.push({
              field: defaultKey,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          }

          break;
        case "between":
          const [startDate, endDate] = filterModel[field].filter.split("|");
          filters.push({
            field: defaultKey,
            operator: KEYWORD_MAPPING.greaterThanOrEqual,
            value: startDate,
          });
          filters.push({
            field: defaultKey,
            operator: KEYWORD_MAPPING.lessThanOrEqual,
            value: endDate,
          });

          break;
        case "equals":
          const { userInputDateString, userInputDate } =
            filterModel[field]?.customizedParam ?? {};
          if (
            field === "accountSummaryDetails-conciergeStatus" &&
            userInputDateString
          ) {
            filters.push({
              field: "conciergeStatus",
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
            if (userInputDateString.indexOf("|") > -1) {
              const conciergeDates = userInputDateString.split("|");
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: conciergeDates[0] ?? "",
              });
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: conciergeDates[1] ?? "",
              });
            } else {
              // filters.push({
              //   field: "conciergeEffectiveFrom",
              //   operator: KEYWORD_MAPPING.greaterThanOrEqual,
              //   value: userInputDateString ?? "",
              // });
              filters.push({
                field: "conciergeEffectiveTo",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: userInputDateString
                  ? userInputDateString
                  : userInputDate.toString(),
              });
            }
            filters.push({
              field,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          } else {
            filters.push({
              field:
                field === "accountSummary-sites" ? "siteExists" : defaultKey,
              operator: KEYWORD_MAPPING.equals,
              value: filterModel[field].filter,
            });
          }
          break;
        default:
          filters.push({
            field: defaultKey,
            operator: KEYWORD_MAPPING[filterModel[field].type],
            value: filterModel[field].filter,
          });
          break;
      }
    });
  }
  return {
    ...result,
    filters,
    segmentation,
  };
};

export const setPagingHeaders = (
  res: NextApiResponse,
  headers: { [key: string]: string }
) => {
  res.setHeader("Total-Pages", headers["total-pages"] ?? 0);
  res.setHeader("Page-Number", headers["page-number"] ?? 0);
  res.setHeader("Total-Elements", headers["total-elements"] ?? 0);
  res.setHeader("Trace-Id", headers["trace-id"] ?? 0);
};

export const setEnterpriseDashboardPagingHeaders = (
  res: NextApiResponse,
  headers: { [key: string]: string },
  sitesCount: number
) => {
  res.setHeader("Total-Pages", headers["total-pages"] ?? 0);
  res.setHeader("Page-Number", headers["page-number"] ?? 0);
  res.setHeader("Total-Elements", sitesCount ?? 0);
  res.setHeader("Trace-Id", headers["trace-id"] ?? 0);
};

export const setUploadHeaders = (
  res: NextApiResponse,
  headers: { [key: string]: string }
) => {
  res.setHeader("Content-Type", headers["Content-Type"] ?? null);
};

export const joinByKey = (
  data: { [key: string]: string | number }[],
  key: string,
  unique = false,
  nullValue = "N/A"
) => {
  let localData = data;
  if (unique) {
    localData = localData.filter(
      (x, i) => localData.findIndex((y) => y[key] === x[key]) === i
    );
  }
  return localData.reduce(
    (prv, cur, index) =>
      index === 0
        ? `${cur[key] ?? nullValue}`
        : `${prv} | ${cur[key] ?? nullValue}`,
    ""
  );
};

export function dispatchCustomEvent<T>(
  type:
    | "DATA_LOADED"
    | "COLUMN_INITIALED"
    | "SAVE_SEARCH"
    | "CIRCLE_REFRESH"
    | "FAVORITE_CHANGE"
    | "CLEAR_FILTER"
    | "SHOW_NOTES"
    | "GET_QUARTER"
    | "SCROLL_CHANGE"
    | "AROFFPC_ALERT_COUNT_CHANGE"
    | "IFRAME_HEIGHT"
    | "REFRESH_ROW"
    | "LEGEND_CLICK"
    | "CALLING_ASSIGN_CHANGE"
    | "ALERT_TOGGLE_CHANGE"
    | "SYNC_CONFIRM"
    | "SELECT_ALL_CHECKBOX"
    | "FOCUS_CHANGE"
    | "EXECUTIVE_CLEAR_FILTER",
  detail?: T
) {
  dispatchEvent(
    new CustomEvent<T>(type, { detail })
  );
}

export const savingColumnState = (params: ColumnEvent, key: string) => {
  const columnState = params.columnApi.getColumnState();
  localStorage.setItem(
    key,
    JSON.stringify(columnState.map((x) => ({ ...x, rowGroupIndex: null })))
  );
};

export const errorHandler = (e: any, res?: NextApiResponse) => {
  const data = e.response?.data ?? e;
  const resData = JSON.stringify(data);
  if (res) {
    res.writeHead(
      e.response?.status ?? 500,
      e.response?.statusText ?? "",
      e.response?.headers
        ? { ...e.response?.headers, "content-length": resData.length }
        : undefined
    );
    res.end(resData);
  }
  return data;
};

export const promiseErrorHandleWithMessage = (
  e: AxiosError<{ message?: string }>,
  defaultMessage = "Fail to do the action",
  options?: ToastOptions
) => {
  toast.error(e?.response?.data?.message || defaultMessage, {
    position: "top-center",
    autoClose: 2000,
    ...options,
  });
};

export const cellValueFormat = (
  cellParams: string,
  cellLabel: string[],
  data: any
) => {
  return data && data[cellParams]
    ? data[cellParams] === "Y"
      ? cellLabel[0]
      : cellLabel[1]
    : "N/A";
};

export const cellMultiValueFormat = (
  cellParams: string[],
  cellLabel: string[],
  data: any
) => {
  // if (data?.siteName === null) return "N/A";
  if (data?.ciOrgId === null) return "N/A";
  let result = "";
  cellParams.forEach((param, index) => {
    result +=
      data && data[param] && data[param] === "Y" ? cellLabel[index] + ", " : "";
  });
  return result ? result.substring(0, result.length - 2) : "Not Enabled";
};

export const getAccountUrlAndBody = (
  headers: any,
  body: {
    searchEnable: boolean;
    filters?: any[];
    sorts?: any[];
    viewType?: string;
  }
) => {
  const url = "/v1/accounts/smart-search";
  const { searchEnable, ...data } = body;
  const isCisco = headers.emailid?.endsWith("@cisco.com");
  let criteria = data;
  // 1. if select favorite, url = /v1/accounts/favorite
  // 2. if user have search, url = /v1/accounts
  // 3. else url = /v1/accounts/my
  if (criteria) {
    const { filters } = criteria;
    if (filters) {
      const isFavorites = filters.some((x: any) => x.field === "favorites");
      if (isFavorites) {
        criteria = {
          ...criteria,
          viewType: "favorite",
          filters: filters.filter((x: any) => x.field !== "favorites"),
        };
      } else if (searchEnable && isCisco) {
        criteria = {
          ...criteria,
          viewType: "all",
        };
      } else {
        criteria = {
          ...criteria,
          viewType: "my",
        };
      }
    } else {
      criteria = {
        ...criteria,
        viewType: "my",
      };
    }
  }
  return { url, body: criteria };
};

export const currencyFormatter = (data?: number) => {
  if (data !== null && data !== undefined) {
    return new Intl.NumberFormat("en", {
      currency: "USD",
      style: "currency",
      maximumFractionDigits: 2,
    }).format(data);
  }
  return "";
};

// export const defaultColumnsSort = (a: ColDef, b: ColDef) => {
//   if (
//     (b.toolPanelClass as string[])?.indexOf("column_disabled") >= 0 &&
//     (a.toolPanelClass as string[])?.indexOf("column_disabled") >= 0
//   ) {
//     return a["headerName"]!.localeCompare(b["headerName"]!);
//   } else if (
//     (b.toolPanelClass as string[])?.indexOf("column_disabled") >= 0 ||
//     (a.toolPanelClass as string[])?.indexOf("column_disabled") >= 0
//   ) {
//     return (
//       ((b.toolPanelClass as string[])?.indexOf("column_disabled") >= 0
//         ? 1
//         : -1) -
//       ((a.toolPanelClass as string[])?.indexOf("column_disabled") >= 0 ? 1 : -1)
//     );
//   } else {
//     return a["headerName"]!.localeCompare(b["headerName"]!);
//   }
// };

/**
 * Sort by
 * 1. start with external
 * 2. toolPanelClass has column_disabled
 * 3. header name
 */

export const defaultColumnsSort = (a: ColDef, b: ColDef) => {
  const campareExternal =
    (a.colId!.startsWith("external") ? -1 : 1) -
    (b.colId!.startsWith("external") ? -1 : 1);
  const campareDisabled =
    ((a.toolPanelClass as string[])?.indexOf("column_disabled") >= 0 ? -1 : 1) -
    ((b.toolPanelClass as string[])?.indexOf("column_disabled") >= 0 ? -1 : 1);
  const campareHeader = a["headerName"]!.localeCompare(b["headerName"]!);
  return campareExternal || campareDisabled || campareHeader;
};

export const disabledColumnsSort = (a: ColDef, b: ColDef) => {
  const campareExternal =
    (a.colId!.startsWith("external") ? -1 : 1) -
    (b.colId!.startsWith("external") ? -1 : 1);
  const campareDisabled =
    ((a.toolPanelClass as string[])?.indexOf("column_disabled") >= 0 ? -1 : 1) -
    ((b.toolPanelClass as string[])?.indexOf("column_disabled") >= 0 ? -1 : 1);
  return campareExternal || campareDisabled;
};

export const handleAgGridFilterIcon = (api: GridApi, type?: string) => {
  const filterModel = api.getFilterModel();
  const {
    externalFilter: _externalFilter,
    externalSearch: _externalSearch,
    externalInactiveSite: _externalInactiveSite,
    externalAlertFilter: _externalAlertFilter,
    externalToggle: _externalToggle,
    externalKeyword: _externalKeyword,
    externalViewTypeFilter: _externalViewTypeFilter,
    externalStatusFilter: _externalStatusFilter,
    externalOverdueFilter: _externalOverdueFilter,
    externalRankFilter: _externalRankFilter,
    externalIncludeInactive: _externalIncludeInactive,
    externalTypeFilter: _externalTypeFilter,
    externalQuery: _externalQuery,
    externalIncludeInactiveSubscription: _externalIncludeInactiveSubscription,
    externalDueDateFilter: _externalDueDateFilter,
    externalProductFilter: _externalProductFilter,
    topCategoryFilter: _topCategoryFilter,
    ...rest
  } = filterModel;
  const keys = Object.keys(rest);
  const filters = document.getElementsByClassName(
    "ag-group-title ag-filter-toolpanel-group-title"
  );
  let filterPanel = undefined;
  // workaround to add filter icon on filter panel
  const activeHeaders = keys.map((key) => {
    const def = api.getColumnDef(key);
    return (
      FILTER_TITLE_MAPPING[
        def.headerName! as keyof typeof FILTER_TITLE_MAPPING
      ] || def.headerName!
    );
  });
  Array.from(filters, (filter) => {
    // workaround to change filter title
    const title = filter.innerHTML;
    if (title in FILTER_TITLE_MAPPING) {
      filter.innerHTML =
        FILTER_TITLE_MAPPING[title as keyof typeof FILTER_TITLE_MAPPING];
    }

    if (activeHeaders.some((h) => filter.innerHTML.split("<span")[0] === h)) {
      if (
        filter.getElementsByClassName("ag-header-icon").length === 0 &&
        !filter.innerHTML.endsWith("</span>")
      ) {
        const icon = document.createElement("span");
        icon.className = "ag-header-icon ag-filter-icon";
        icon.innerHTML =
          '<span class="ag-icon ag-icon-filter" unselectable="on">&nbsp;</span>';
        filter.appendChild(icon);
      }
    } else {
      const icon = filter.getElementsByClassName("ag-filter-icon");
      if (icon.length > 0) {
        icon[0].remove();
      }
    }
  });

  if (type) {
    filterPanel = document
      .getElementById(type)
      ?.getElementsByClassName("ag-side-button-button")[1];
  } else {
    filterPanel = document.getElementsByClassName("ag-side-button-button")[1];
  }
  if (filterPanel) {
    if (keys.filter((x) => !x.startsWith("accountSegmentation")).length > 0) {
      filterPanel.classList.add("active");
    } else {
      filterPanel.classList.remove("active");
    }
  }
  const segmentationPanel = document.getElementsByClassName(
    "ag-side-button-button"
  )[2];
  if (segmentationPanel) {
    if (keys.filter((x) => x.startsWith("accountSegmentation")).length > 0) {
      segmentationPanel.classList.add("active");
    } else {
      segmentationPanel.classList.remove("active");
    }
  }
};

export function rowSpanning<T>(data: T[], key: keyof T) {
  return data
    .reduce((result: unknown[], item) => {
      if (result.indexOf(item[key]) < 0) {
        result.push(item[key]);
      }
      return result;
    }, [])
    .reduce((result: any, value: unknown) => {
      const children = data.filter((item) => item[key] === value);
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          rowSpan: children.length - index === 0 ? 1 : children.length - index,
          groupFlag: index === 0,
        }))
      );
      return result;
    }, []);
}

export const getCookieByKey = (key: string, cookie?: string) => {
  if (!cookie) return "";
  const pattern = `${key}=([^;]*)`;
  const regex = new RegExp(pattern);
  const result = regex.exec(cookie);
  if (result?.length === 2) {
    return result[1];
  }
  return "";
};

export const getCIRedirectURL = (props: {
  authServer: string;
  clientId: string;
  redirectURL: string;
  scope: string;
  emailId?: string;
}) => {
  const { authServer, clientId, redirectURL, scope, emailId } = props;
  const url = `${authServer}/idb/oauth2/v1/authorize?response_type=token&client_id=${clientId}&scope=${encodeURIComponent(
    scope ?? ""
  )}&redirect_uri=${encodeURIComponent(redirectURL ?? "")}${
    emailId ? `&email=${emailId}` : ""
  }`;
  return url;
};

/**
 * Only run this on SERVER!
 * emailId, firstName, lastName are with double quote, need remove
 */
export const checkPermission = async (
  req: IncomingMessage,
  res: ServerResponse
) => {
  /**
   * Step 1: get needed info from cookie, if token/emailId is missing, redirect to login
   */
  const token = getCookieByKey(TOKEN_COOKIE, req.headers.cookie);
  const uuid = getCookieByKey(UUID_COOKIE, req.headers.cookie);
  const emailId = getCookieByKey(EMAILID_COOKIE, req.headers.cookie).replace(
    /"/g,
    ""
  );
  const firstName = getCookieByKey(
    FIRST_NAME_COOKIE,
    req.headers.cookie
  ).replace(/"/g, "");
  const lastName = getCookieByKey(LAST_NAME_COOKIE, req.headers.cookie).replace(
    /"/g,
    ""
  );
  if (!token || !emailId) {
    res.writeHead(302, {
      Location: "/spng",
      "Set-Cookie": [
        `${TOKEN_COOKIE}=;Path=/`,
        `${LAST_URL_COOKIE}=${req.url};Path=/`,
      ],
    });
    res.end();
    return null;
  }

  /**
   * Step 2: get infor from RBAC and check
   */

  const axios = useAxios({
    token,
    emailId,
    uuid,
    req,
  });

  const fsAxios = useAxios(
    {
      token,
      emailId,
      req,
    },
    "featureService"
  );
  //to fix Jest mock issue
  const permissions = await initPermissions(axios, fsAxios, uuid);
  if (typeof permissions === "number") {
    if (permissions === 401) {
      res.writeHead(302, {
        Location: "/spng",
        "Set-Cookie": [
          `${TOKEN_COOKIE}=;Path=/`,
          `${LAST_URL_COOKIE}=${req.url};Path=/`,
        ],
      });
    } else {
      res.writeHead(302, {
        Location: "/spng/error",
      });
    }
    res.end();
    return null;
  }

  if (!permissions) {
    res.writeHead(302, {
      Location: "/spng/error",
    });
    res.end();
    return null;
  }

  //TODO update when BE is ready, temporary solution
  if (
    [ROLE.CISCO_WIDE_ROLE, ROLE.CISCO_WIDE_SALES_ROLE].includes(
      permissions.role
    )
  ) {
    res.writeHead(302, {
      Location: "/spng/error",
    });
    res.end();
    return null;
  }

  console.info("Get permissions =================");
  console.info(permissions);
  console.info("End get permissions=================");
  const { url: pathname } = req;
  // when in dev, there will have some request for json, exclude them
  if (pathname?.includes(".json")) {
  } else {
    const flatArray = (result: MenuItemDef[], item: MenuItemDef) => {
      const children: MenuItemDef[] = (item.children || []).reduce(
        flatArray,
        []
      );
      if (children.length) {
        return result.concat(children);
      } else {
        result.push(item);
        return result;
      }
    };

    const flatedDef = [...MENU_DEFINITIONS, ...RESOURCE_DEFINITION].reduce(
      flatArray,
      []
    );
    const def = flatedDef.find((x) => pathname?.split("?")?.[0] === x.href);
    /**
     * case 1: Can't find route
     * case 2: menu list does not contains this route
     * case 3: have toggle set on route and user don't have this toggle as true
     */
    if (
      !def ||
      (!def.hide &&
        (!permissions.menus.some((y) => y === def.authKey) ||
          (def.toggle &&
            permissions.toggles &&
            !permissions.toggles[def.toggle])))
    ) {
      console.log("User don't have access " + pathname);
      res.writeHead(302, { Location: "/spng/error" });
      res.end();
      return null;
    }
  }

  res.setHeader("Set-Cookie", `${ROLE_COOKIE}=${permissions.role};Path=/`);

  return {
    props: {
      authDetail: { ...permissions, emailId },
      initialState: {
        token,
        emailId,
        uuid,
        firstName,
        lastName,
      },
    },
  };
};

export const enterpriseSearchEnable = (url: string, searchEnable: string) => {
  let newURL = "";
  if (searchEnable === "true") {
    newURL = `${url}?viewScope=all`;
  } else if (searchEnable === "false") {
    newURL = `${url}?viewScope=my`;
  }
  if (!url.includes("overview")) {
    // only account api add param
    newURL = `${newURL}&includeContacts=true`;
  }
  return newURL;
};

export const sortColumn = (columnDefs: any, key: string) => {
  columnDefs.map((item: any) => {
    if (item.hasOwnProperty("children")) {
      //sortColumn(item.children, key);
      item.children.sort((a: any, b: any) => {
        if (
          (b.toolPanelClass as string[])?.indexOf("children_column_disabled") &&
          (a.toolPanelClass as string[])?.indexOf("children_column_disabled")
        ) {
          return a[key].localeCompare(b[key]);
        } else if (
          (b.toolPanelClass as string[])?.indexOf("children_column_disabled") >=
            0 ||
          (a.toolPanelClass as string[])?.indexOf("children_column_disabled") >=
            0
        ) {
          return (
            ((b.toolPanelClass as string[])?.indexOf(
              "children_column_disabled"
            ) >= 0
              ? 1
              : -1) -
            ((a.toolPanelClass as string[])?.indexOf(
              "children_column_disabled"
            ) >= 0
              ? 1
              : -1)
          );
        } else {
          return a[key].localeCompare(b[key]);
        }
      });
    }
  });
};

export const sortByASC = (a: any, b: any) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const sortByDesc = (a: any, b: any) => {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
};

export const columnVisiableWatcher = (params: ColumnVisibleEvent) => {
  let needUpdate = false;
  const { columnApi } = params;
  const columnsState = columnApi.getColumnState();
  const state = columnsState.map((col) => {
    const columnDef = columnApi.getColumn(col.colId).getColDef();
    const toolPanelClass = (columnDef.toolPanelClass as string[]) ?? [];
    if (
      (toolPanelClass.includes("children_column_disabled") ||
        toolPanelClass.includes("column_disabled")) &&
      col.hide
    ) {
      needUpdate = true;
      return { ...col, hide: false };
    }
    if (
      ((col.colId.startsWith("external") &&
        col.colId.indexOf("Checkbox") < 0) ||
        toolPanelClass.includes("skip_visiable_check")) &&
      !col.hide
    ) {
      needUpdate = true;
      return { ...col, hide: true };
    }
    return col;
  });
  if (needUpdate) {
    columnApi.setColumnState(state);
  }
};

export const getContextMenu = (params: GetContextMenuItemsParams) => {
  if (params.column?.getColDef().field === "accountName") {
    const openLink = {
      name: "Open Link in New Tab",
      icon: '<i class="cui-icon icon icon-call-outgoing_16" />',
      action() {
        const url = `/spng/summary?accountId=${params.node.data.accountId}`;
        window.open(url);
      },
    };
    const copyLink = {
      name: "Copy Link Address",
      icon: '<i class="cui-icon icon icon-copy_16" />',
      action() {
        const url = `${window.location.host}/spng/summary?accountId=${params.node.data.accountId}`;
        if (window.top !== window.self) {
          window.parent.postMessage(
            { type: "copy-to-clipboard", toCopy: url },
            "*"
          );
        } else {
          window.navigator.clipboard.writeText(url);
        }
      },
    };
    return [
      ...params.defaultItems!.filter(
        (item) => !item?.toLowerCase().includes("export")
      ),
      openLink,
      copyLink,
    ];
  } else {
    return params.defaultItems!.filter(
      (item) => !item?.toLowerCase().includes("export")
    )!;
  }
};

// fix reset column issue
export const getMainMenuItems = (params: GetMainMenuItemsParams) => {
  const resetCols = {
    name: "Reset Columns",
    action() {
      const state = params.columnApi?.getColumnState();
      const fixedCols = ["externalCheckbox", "accountId", "accountName"];
      state?.forEach((col) => {
        if (fixedCols.includes(col.colId)) {
          col.hide = false;
        } else {
          // get alert column status
          const showAlert = localStorage.getItem(PORTFOLIO_SHOW_ALERT)
            ? localStorage.getItem(PORTFOLIO_SHOW_ALERT) === "true"
              ? true
              : false
            : false;
          if (col.colId === "externalAlertColumn") {
            col.hide = !showAlert;
          } else {
            col.hide = true;
          }
        }
      });
      params.columnApi?.setColumnState(state!);
    },
  };
  params.defaultItems.pop();
  return [...params.defaultItems, resetCols];
};

export const getExecutiveMainMenuItems = (params: GetMainMenuItemsParams) => {
  const resetCols = {
    name: "Reset Columns",
    action() {
      const state = params.columnApi?.getColumnState();
      const fixedCols = [
        "externalCheckbox",
        "alertCol",
        "accountId",
        "accountName",
      ];
      state?.forEach((col) => {
        if (fixedCols.includes(col.colId)) {
          col.hide = false;
        } else {
          // get alert column status
          const showAlert = localStorage.getItem(EXECUTIVE_SHOW_ALERT)
            ? localStorage.getItem(EXECUTIVE_SHOW_ALERT) === "true"
              ? true
              : false
            : false;
          if (col.colId === "alertCol") {
            col.hide = !showAlert;
          } else {
            col.hide = true;
          }
        }
      });
      params.columnApi?.setColumnState(state!);
    },
  };
  params.defaultItems.pop();
  return [...params.defaultItems, resetCols];
};

export const getSummarySiteName = (siteSummary: SiteSummary[]) => {
  let sitename = "null";
  const sites: SiteInSiteSummary[] = [];
  siteSummary.forEach((s) => sites.push(...s.sites));
  if (sites.length === 1) {
    const site = sites[0];
    sitename = `${site.hasUsage ? site.product : "NO USAGE"}-${site.siteUrl}`;
  } else if (sites.length > 1) {
    sitename = `ALL-${sites[0].product}`;
  }
  return sitename;
};

export const handleMeetingUsageLoading = (
  selectedSites: SiteSearchOption[],
  chartRef: MutableRefObject<HighchartRef | null>,
  data?:
    | AggregatedMeetingUsageObject
    | AggregatedLegacyMeetingUsageObject
    | AggregatedLegacyMeetingUtilizationObject
    | AggregatedCallingObject
    | WebexAppDataUsage,
  type?: string
) => {
  if (selectedSites.length) {
    if (data) {
      if (data.categories.length) {
        chartRef.current?.chart?.hideLoading();
      } else {
        chartRef.current?.chart?.showLoading("No Data Available");
      }
    } else {
      chartRef.current?.chart?.showLoading("Fetching data...");
    }
  } else {
    if (type !== "webexApp") {
      chartRef.current?.chart?.showLoading("Please select at least one site");
    } else {
      if (data) {
        if (data.categories.length) {
          chartRef.current?.chart?.hideLoading();
        } else {
          chartRef.current?.chart?.showLoading("No Data Available");
        }
      } else {
        chartRef.current?.chart?.showLoading("Fetching data...");
      }
    }
  }
};

export const handleTeamsUsageLoading = (
  chartRef: MutableRefObject<HighchartRef | null>,
  data?:
    | AggregatedActiveUserObject
    | AggregatedTeamUsageObject
    | AggregatedCallingObject
    | OldAggregatedCallingObject
) => {
  if (data) {
    if (data.categories.length) {
      chartRef.current?.chart?.hideLoading();
    } else {
      chartRef.current?.chart?.showLoading("No Data Available");
    }
  } else {
    chartRef.current?.chart?.showLoading("Fetching data...");
  }
};
// return result => true: show no data; false: not show no data
export const enableShowNoData = (arr: any) => {
  const allCharts: boolean[] = [];
  const lengendArr = arr.map((i: any) => i.userOptions.data);
  if (lengendArr.length === 1) {
    if (lengendArr[0].some((s: number) => s > 0)) {
      allCharts.push(false);
    } else {
      allCharts.push(true);
    }
  } else {
    lengendArr.forEach((item: number[]) => {
      if (item.some((k) => k > 0)) {
        allCharts.push(false);
      } else {
        allCharts.push(true);
      }
    });
  }
  return allCharts.every((d) => d === true);
};

export const newColumnDefaultShow = (
  newColIds: string[],
  columnSavedState: ColumnState[],
  startIndex = 2
) => {
  newColIds.forEach((id) => {
    if (!columnSavedState.find((x: ColumnState) => x.colId === id)) {
      columnSavedState.splice(startIndex, 0, {
        aggFunc: null,
        colId: id,
        hide: false,
        pinned: undefined,
        pivotIndex: null,
        rowGroupIndex: null,
      });
    }
  });
  return columnSavedState;
};

/**
 * Cisco Note manager can edit, Partner note can't
 */
export const isNoteEditable = (
  authDetail: AuthDetail,
  note?: Partial<Note>
) => {
  const ciscoNote = note?.createdBy?.endsWith("@cisco.com");
  const editable =
    authDetail.emailId === note?.createdBy ||
    (authDetail.role === ROLE.MANAGER_ROLE && ciscoNote);
  return editable;
};

export const addColumnsSelectLabel = () => {
  const columnsSelectLabel: HTMLElement | null = document.querySelector(
    ".ag-column-select-header-checkbox label"
  );
  if (columnsSelectLabel) {
    columnsSelectLabel.classList.remove("ag-hidden");
    columnsSelectLabel.innerText = "Select All";
    columnsSelectLabel.style.cssText += "padding-left:6px";
  }
};

export const transferToNumber = (inputNumber: any) => {
  if (isNaN(inputNumber)) {
    return inputNumber;
  }
  inputNumber = "" + inputNumber;
  inputNumber = parseFloat(inputNumber);
  const eformat = inputNumber.toExponential();
  const tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/);
  const number = inputNumber.toFixed(
    Math.max(0, (tmpArray[1] || "").length - tmpArray[2])
  );
  return number;
};

export const useHealthScoreColor = (value: number) => {
  let color;
  if (value > 75) {
    color = "#3BBF47";
  } else if (value > 50) {
    color = "#FC9D03";
  } else if (value > 25) {
    color = "#F26B1D";
  } else {
    color = "#D4371C";
  }
  return color;
};

export const useHealthScoreTxt = (value: number) => {
  let txt;
  if (value > 75) {
    txt = "Significant Risk";
  } else if (value > 50) {
    txt = "Moderate Risk";
  } else if (value > 25) {
    txt = "Minimal Risk";
  } else {
    txt = "No Risk";
  }
  return txt;
};

export const isNullOrUndefined = (value: unknown) => {
  return value == null || value == undefined;
};

export const trackButton = (btn: string) => {
  const pageUrl = location?.pathname;
  const pathArr = location?.pathname?.split("/");
  const pageName = pathArr[pathArr.length - 1];
  const btnList: { [key: string]: string } = {
    all: "All Columns",
    display: "Displayed Columns",
    selected: "Selected Columns",
    customerContacts: "Cisco Account Team Contacts",
    teamContacts: "End-Customer Contacts",
  };
  const btnName = Object.keys(btnList).includes(btn)
    ? `Download_${btnList[btn]}`
    : btn;

  if (pageUrl && pageName && btn) {
    // eslint - disable - next - line;
    const _paq = (window as any)._paq || [];
    _paq.push(["setCustomVariable", 1, pageName, pageUrl, "page"]);
    _paq.push(["setCustomVariable", 2, "Button", btnName, "page"]);
    _paq.push(["trackEvent", "Button", "track", "Usage", btnName]);
  }
};

export const getBenchmarkCustomLayout = (
  defs: Array<
    (ColDef | ColGroupDef) & { groupId?: string; groupIndex?: number }
  >,
  groupName?: string
) => {
  // 1. filter out all other group except accountSummaryDetails
  // 2. filter out accoutSummaryDetails
  // 3. filter out not have groupHeader in accoutSummaryDetails children
  // 4. filter out have groupHeader in accoutSummaryDetails children, group with Partner
  // 5. combine all above.
  const extraCols = defs.filter((item) => item?.groupId !== groupName);
  const summaryDetailDefs: (ColGroupDef & {
    children?: (ColDef & { groupId?: string; groupIndex?: number })[];
  })[] = [];
  defs
    .filter((item) => item?.groupId === groupName)
    .forEach(
      (
        child: (ColDef | ColGroupDef) & {
          groupId?: string;
          children?: (ColDef & { groupId?: string; groupIndex?: number })[];
        }
      ) => {
        if (child?.children?.length) {
          const group = child?.children?.reduce<{
            [key: string]: {
              children: Array<{ colId: string; headerName: string }>;
            };
          }>((prv: any, cur: any) => {
            //@ts-ignore
            const def = prv[cur.groupId] || { children: [] };
            def.children.push({
              colId: cur.colId || cur.field || "",
              headerName: cur.headerName!,
              ...cur,
            });
            def.children.sort(
              (a: any, b: any) => (a.groupIndex ?? 0) - (b.groupIndex ?? 0)
            );
            //@ts-ignore
            if (cur.groupId) {
              prv[cur.groupId] = def;
            }
            return prv;
          }, {});
          const groupLayout = [];
          for (const [k, v] of Object.entries(group)) {
            groupLayout.push({
              colId: k,
              headerName: k,
              ...v,
            });
          }
          const extraChildren = child?.children.filter((i) => !!!i.groupId);
          summaryDetailDefs.push({
            headerName: child.headerName,
            groupId: child.groupId,
            children: [
              ...groupLayout,
              ...extraChildren,
            ].sort((a: any, b: any) =>
              a["headerName"]!.localeCompare(b["headerName"]!)
            ),
          });
        }
      }
    );
  const layout = [...extraCols, ...summaryDetailDefs];
  layout.sort((a: any, b: any) => {
    return a["headerName"]!.localeCompare(b["headerName"]!);
  });
  // console.log(layout);
  return layout;
};

export const getCustomLayout = (
  defs: Array<
    ColDef & {
      groupHeader?: string;
      groupIndex?: number;
      subGroupHeader?: string;
    }
  >
) => {
  /**
   * 1. Filter out all defs without groupHeader
   * 2. Filter out all defs with groupHeader and do transform
   * 3. Merge two list
   */
  const exceptGroupDef = defs
    .filter((item) => !!!item?.groupHeader)
    .map((x) => ({
      colId: x.colId || x.field,
      headerName: x.headerName,
      toolPanelClass: x.toolPanelClass,
    }));

  const exceptSubGroupDef = defs
    .filter((item) => !!item?.groupHeader)
    .sort((a: any, b: any) => {
      const sortDisabled =
        ((a.toolPanelClass as string[])?.indexOf("column_disabled") >= 0
          ? -1
          : 1) -
        ((b.toolPanelClass as string[])?.indexOf("column_disabled") >= 0
          ? -1
          : 1);
      const sortHeader = a["headerName"]!.localeCompare(b["headerName"]!);
      return (
        sortDisabled || (a.groupIndex ?? 0) - (b.groupIndex ?? 0) || sortHeader
      );
    })
    .filter((item) => !!!item.subGroupHeader)
    .reduce<{
      [key: string]: {
        children: Array<{
          colId: string;
          headerName: string;
          subGroupHeader?: string;
        }>;
      };
    }>((prv, cur) => {
      //@ts-ignore
      const def = prv[cur.groupHeader] || { children: [] };
      def.children.push({
        colId: cur.colId || cur.field || "",
        headerName: cur.headerName!,
        subGroupHeader: cur.subGroupHeader,
      });
      //@ts-ignore
      prv[cur.groupHeader] = def;
      return prv;
    }, {});

  const subGroupDef = defs
    .filter((item) => !!item?.groupHeader)
    .sort((a: any, b: any) => {
      const sortDisabled =
        ((a.toolPanelClass as string[])?.indexOf("column_disabled") >= 0
          ? -1
          : 1) -
        ((b.toolPanelClass as string[])?.indexOf("column_disabled") >= 0
          ? -1
          : 1);
      const sortHeader = a["headerName"]!.localeCompare(b["headerName"]!);
      return (
        sortDisabled || (a.groupIndex ?? 0) - (b.groupIndex ?? 0) || sortHeader
      );
    })
    .filter((item) => !!item.subGroupHeader)
    .sort((a: any, b: any) => {
      if (a.subGroupHeader === b.subGroupHeader) {
        const deviceOrder = [
          "Last 6 Months",
          "Last 12 Months",
          "Last 24 Months",
          "Last 3 Years",
        ];
        const sortDeviceHeader =
          deviceOrder.indexOf(a["headerName"]?.split("(")?.[1]?.slice(0, -1)) -
          deviceOrder.indexOf(b["headerName"]?.split("(")?.[1]?.slice(0, -1));
        return sortDeviceHeader;
      }
      return 1;
    })
    .reduce<{
      [key: string]: {
        children: Array<{
          colId: string;
          headerName: string;
          subGroupHeader?: string;
        }>;
      };
    }>((prv, cur) => {
      //@ts-ignore
      const def = prv[cur.groupHeader] || { children: [] };
      def.children.push({
        colId: cur.colId || cur.field || "",
        headerName: cur.headerName!,
        subGroupHeader: cur.subGroupHeader,
      });
      //@ts-ignore
      prv[cur.groupHeader] = def;
      return prv;
    }, {});
  const exceptSubGroupLayout = [];
  for (const [k, v] of Object.entries(exceptSubGroupDef)) {
    exceptSubGroupLayout.push({
      colId: k,
      headerName: k,
      ...v,
    });
  }
  const subGroupLayout = [];
  for (const [k, v] of Object.entries(subGroupDef)) {
    subGroupLayout.push({
      colId: k,
      headerName: k,
      ...v,
    });
  }

  const layout = [
    ...exceptGroupDef,
    ...exceptSubGroupLayout,
    ...subGroupLayout,
  ];
  layout.sort((a: any, b: any) => defaultColumnsSort(a, b));
  return layout;
};

export const getContactLayout = (defs: Array<ColDef | ColGroupDef>) => {
  const extraCols = defs
    .filter((item) => item?.headerName === "Contact")
    .map((x: ColDef & { children?: ColDef[] }) => ({
      colId: x.colId,
      headerName: x.headerName,
      children: x?.children?.sort((a: any, b: any) =>
        a["headerName"]!.localeCompare(b["headerName"]!)
      ),
    }));
  const contactDefs: (ColGroupDef & {
    children?: (ColDef & {
      groupHeader?: string;
      groupId?: string;
      subGroupHeader?: string;
    })[];
  })[] = [];
  defs
    .filter((item) => item?.headerName === "Account")
    .forEach(
      (
        child: (ColDef | ColGroupDef) & {
          children?: (ColDef & {
            groupHeader?: string;
            groupId?: string;
            subGroupHeader?: string;
          })[];
        }
      ) => {
        if (child?.children?.length) {
          const group = child?.children
            ?.filter((i) => i.subGroupHeader)
            ?.reduce<{
              [key: string]: {
                children: Array<{ colId: string; headerName: string }>;
              };
            }>((prv: any, cur: any) => {
              //@ts-ignore
              const def = prv[cur.subGroupHeader] || { children: [] };
              // debugger;
              // console.log(cur);
              def.children.push({
                colId: cur.colId || cur.field || "",
                headerName: cur.headerName!,
              });
              //@ts-ignore
              prv[cur.subGroupHeader] = def;
              return prv;
            }, {});
          const groupLayout = [];
          for (const [k, v] of Object.entries(group)) {
            groupLayout.push({
              colId: k,
              headerName: k,
              ...v,
            });
          }
          const extraChildren = child?.children.filter(
            (i) => !i.subGroupHeader
          );
          contactDefs.push({
            headerName: child.headerName,
            children: [
              ...groupLayout,
              ...extraChildren,
            ].sort((a: any, b: any) =>
              a["headerName"]!.localeCompare(b["headerName"]!)
            ),
          });
        }
      }
    );

  const layout = [...extraCols, ...contactDefs];
  // console.log(layout);
  layout.sort((a: any, b: any) => {
    return a["headerName"]!.localeCompare(b["headerName"]!);
  });
  // console.log(layout);
  return layout;
};

export const transformSentEmailsRequest = (
  req: Partial<IServerSideGetRowsRequest>
) => {
  let result: {
    searchEnable?: boolean;
    keyword?: string;
    sorts?: any;
    filters?: any;
    viewType?: string;
  } = {};
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  let searchEnable = false;
  let keyword = "";
  let viewType = "all";

  // create type for filterModel and sortModel
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  const sortModel: { colId: string; sort: string }[] = sortModelReq?.map(
    (x: { colId: string; sort: string }) => ({
      colId:
        x.colId in SORT_KEY_MAPPING
          ? SORT_KEY_MAPPING[x.colId as keyof typeof SORT_KEY_MAPPING]
          : x.colId,
      sort: x.sort,
    })
  );
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      customizedParam?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in FILTER_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [FILTER_KEY_MAPPING[k as keyof typeof FILTER_KEY_MAPPING]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }
  // transform sort
  if (sortModel?.length > 0) {
    const sorts = sortModel.map((i) => ({ field: i.colId, sort: i.sort }));
    result = { ...result, sorts };
  }
  const filterKeys = Object.keys(filterModel);
  // transform filter
  const filters: {
    field: string;
    operator: string;
    value: string | number | string[] | boolean | { [key: string]: string[] };
  }[] = [
    // { field: "includeContacts", operator: "eq", value: 1 },
    // { field: "includeDismissals", operator: "eq", value: 1 },
  ];
  if (filterKeys.length > 0) {
    filterKeys.forEach((field) => {
      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalFilter") {
            switch (filterModel.externalFilter.filter) {
              case "Sent by Me":
                viewType = "my";
                break;
              case "Sent by Others":
                viewType = "other";
                break;
              case "All":
                viewType = "all";
                break;
              case "Dry run":
                viewType = "my";
                filters.push({ field: "dryRun", operator: "eq", value: 1 });
                break;
            }
          } else if (field === "externalSearch") {
            // criteria from search bar, eg: true%|%apple
            const [
              include,
              // _column,
              ...values
            ] = filterModel.externalSearch.filter.split("%|%");
            // if empty will have value [""]
            if (!!values[0]) {
              // filters.push({
              //   field: column,
              //   operator: KEYWORD_MAPPING.contains,
              //   value: values.join("%|%"),
              // });
              searchEnable = true;
              keyword = values.join("%|%");
            }
            if (include === "true") {
              filters.push({
                field: "includeInactive",
                operator: KEYWORD_MAPPING.equals,
                value: 1,
              });
            }
          } else if (field === "externalAlertFilter") {
            switch (filterModel.externalAlertFilter.filter) {
              case "Auto Renew Off/Pending Cancel":
                filters.push({
                  field: "arOffPCAlert",
                  operator: KEYWORD_MAPPING.equals,
                  value: 1,
                });
                break;
            }
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.contains,
              value: filterModel[field].filter,
            });
          }
          break;
        case "in":
          if (field === "partnerFilter") {
            const filterValue: { [key: string]: string[] } = {};
            filterModel[field].filter.split("|").forEach((item) => {
              const [type, ...value] = item.split("%-%");
              filterValue[type] = (filterValue[type] || []).concat([
                value.join(""),
              ]);
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else if (field === "fiscalRenewalMonth") {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              const [_type, ...value] = item.split("%-%");
              filterValue.push(value.join(""));
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          }

          break;
        case "between":
          const [startDate, endDate] = filterModel[field].filter.split("|");
          filters.push({
            field,
            operator: KEYWORD_MAPPING.greaterThanOrEqual,
            value: startDate,
          });
          filters.push({
            field,
            operator: KEYWORD_MAPPING.lessThanOrEqual,
            value: endDate,
          });

          break;
        case "equals":
          filters.push({
            field: field === "sites" ? "siteExists" : field,
            operator: KEYWORD_MAPPING.equals,
            value: filterModel[field].filter,
          });
          const { userInputDateString } =
            filterModel[field]?.customizedParam ?? {};
          if (field === "expandPlanExists" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "expandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
          } else if (
            field === "partnerExpandPlanExists" &&
            userInputDateString
          ) {
            if (userInputDateString.indexOf("|") > -1) {
              const partnerExpandPlanDates = userInputDateString.split("|");
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: partnerExpandPlanDates[0] ?? "",
              });
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: partnerExpandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: "partnerExpandPlanCreatedDate",
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
          }
          break;
        default:
          filters.push({
            field,
            operator: KEYWORD_MAPPING[filterModel[field].type],
            value: filterModel[field].filter,
          });
          break;
      }
    });
  }
  return {
    ...result,
    filters,
    searchEnable,
    keyword,
    viewType,
  };
};

export const downloadHandler = (
  axios: AxiosInstance,
  btnName: string,
  url: string,
  body: any,
  filename?: string,
  message = "Please wait while we are fetching the data"
) => {
  trackButton(btnName);
  const toastContent = () => (
    <>
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
      <LinearProgress color="primary" style={{ borderRadius: 5 }} />
    </>
  );
  const toastId = `progress${random(5, true)}`;
  toast.info(toastContent(), {
    toastId,
    closeOnClick: false,
    autoClose: false,
    closeButton: true,
    hideProgressBar: false,
  });
  if (btnName === "Health Report Download") {
    axios.post(url, body).then((res) => {
      const result = res?.data;
      if (result?.msg === "success") {
        const params = {
          pdf: "healthMonitor",
          siteName: result?.data?.[0].SITENAME,
          startDate: result?.data?.[0].STARTDATE,
          endDate: result?.data?.[0].ENDDATE,
          dateType: "week",
          joinMeetingUserType: "RETURN",
          siteId: result?.data?.[0].HGSSITEID,
          orgId: result?.data?.[0].ORGID,
          company: body.company ?? "Unknown company",
          siteOrgType: "siteId",
        };
        const test = Object.entries(params).map(
          ([key, value]) => `${key}=${value}`
        );
        window.open(
          `https://oneview.webex.com/healthMonitorPDF?${encodeURIComponent(
            test.join("&")
          )}`
        );
        toast.dismiss(toastId);
      } else {
        toast.dismiss(toastId);
        toast.error("Sorry, No site data found!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    });
  } else {
    axios
      .post(url, body, {
        responseType: "arraybuffer",
        onDownloadProgress: (e) => {
          if (e.total) {
            toast.update(toastId, {
              progress: e.loaded / e.total,
              render: (
                <>
                  <p dangerouslySetInnerHTML={{ __html: message }}></p>
                  <p>{((e.loaded * 100) / e.total).toFixed(0)}%</p>
                </>
              ),
            });
          }
        },
      })
      .then((res) => {
        saveAs(
          new Blob([res.data], {
            type: res.headers["content-type"],
          }),
          filename || res.headers.filename || "file"
        );
      })
      .catch((e) => {
        console.error(e.response);
        const res = JSON.parse(Buffer.from(e.response.data).toString("utf8"));
        toast.error(res?.message || "Failed to download", {
          position: "top-center",
          autoClose: 3000,
        });
      })
      .finally(() => {
        toast.dismiss(toastId);
      });
  }
};

export const toggleFilter = (y: GirdColumnDef, authDetail: AuthDetail) =>
  (!y.toggle && !y.excludeWhenToggleOn) ||
  (!y.toggle &&
    y.excludeWhenToggleOn &&
    authDetail.toggles &&
    !authDetail.toggles[y.excludeWhenToggleOn]) ||
  (y.toggle && authDetail.toggles && authDetail.toggles[y.toggle]);

export function getItemFromLocalStorage<T>(key: string, defaultValue: T): T {
  if (typeof window === "undefined") return defaultValue;
  const saved = localStorage.getItem(key);
  try {
    if (saved) {
      return JSON.parse(saved);
    }
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
  return defaultValue;
}

export const transformAlertAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>
) => {
  let result: {
    includeDismissed?: boolean;
    sort?: any;
    filters?: any;
  } = {};

  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  // create type for filterModel and sortModel
  const sortModel: { colId: string; sort: string }[] = sortModelReq;
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      filterValue?: string | boolean;
      customizedParam?: any;
    };
  } = {
    // alertType: {
    //   filterType: "checkboxFilter",
    //   type: "in",
    //   filter: "USAGE|CHURN_RISK|SUPPORT|HEALTH",
    // },
  };
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in SUMMARY_ALERT_FILTER_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [SUMMARY_ALERT_FILTER_KEY_MAPPING[
          k as keyof typeof SUMMARY_ALERT_FILTER_KEY_MAPPING
        ]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }
  // transform sort
  if (sortModel?.length > 0) {
    const sortStr = sortModel
      .map((x: { colId: string; sort: string }) => ({
        colId:
          x.colId in SUMMARY_ALERT_SORT_KEY_MAPPING
            ? SUMMARY_ALERT_SORT_KEY_MAPPING[
                x.colId as keyof typeof SUMMARY_ALERT_SORT_KEY_MAPPING
              ]
            : x.colId,
        sort: x.sort,
      }))
      .map((x: { colId: string; sort: string }) => x.colId + ":" + x.sort);
    if (sortStr) {
      result = { ...result, sort: sortStr[0] };
    }
  }
  const filterKeys = Object.keys(filterModel);
  let filters: any = {};
  // transform filter
  if (filterKeys.length > 0) {
    filterKeys.forEach((field) => {
      switch (filterModel[field].type) {
        case "contains":
          const value = `${KEYWORD_MAPPING.contains}:${filterModel[field].filter}`;
          if (field in filters) {
            if (Array.isArray(filters[field])) {
              filters[field].push(value);
            } else {
              filters = {
                ...filters,
                [field]: [filters[field], value],
              };
            }
          } else {
            filters = {
              ...filters,
              [field]: value,
            };
          }
          break;
        case "in":
          const filterValue: string[] = [];
          filterModel[field].filter.split("|").forEach((item) => {
            if (item !== "") {
              filterValue.push(item);
            }
          });
          filters = {
            ...filters,
            [field]: `${KEYWORD_MAPPING.in}:${filterValue}`,
          };
          break;
        case "between":
          const [startDate, endDate] = filterModel[field].filter.split("|");
          filters = {
            ...filters,
            [field]: `${KEYWORD_MAPPING.greaterThanOrEqual}:${startDate},${KEYWORD_MAPPING.lessThanOrEqual}:${endDate}`,
          };
          break;
        case "equals":
          const { userInputDateString } =
            filterModel[field]?.customizedParam ?? {};
          if (field === "lastModifiedDate" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters = {
                ...filters,
                [field]: `${KEYWORD_MAPPING.greaterThanOrEqual}:${
                  expandPlanDates[0] ?? ""
                },${KEYWORD_MAPPING.lessThanOrEqual}:${
                  expandPlanDates[1] ?? ""
                }`,
              };
            } else {
              filters = {
                ...filters,
                [field]: `${KEYWORD_MAPPING.greaterThanOrEqual}:${
                  userInputDateString ?? ""
                }`,
              };
            }
          }
          break;

        default:
          filters = {
            ...filters,
            [field]: `${KEYWORD_MAPPING[filterModel[field].type]}:${
              filterModel[field].filter
            }`,
          };
      }
    });
    result = { ...result, ...filters, includeDismissed: true };
  }
  // console.log(result);
  return { ...result, includeDismissed: true };
};

export const getViewType = (searchEnable: boolean, filters: any) => {
  let viewType = "";
  const email = Cookies.get(EMAILID_COOKIE);
  const mockEmail = Cookies.get(MOCK_USER_COOKIE);
  const isCisco =
    email?.endsWith("@cisco.com") || mockEmail?.endsWith("@cisco.com");
  if (filters) {
    const isFavorites = filters.some((x: any) => x.field === "favorites");
    if (isFavorites) {
      viewType = "favorite";
    } else if (searchEnable && isCisco) {
      viewType = "all";
    } else {
      viewType = "my";
    }
  } else {
    viewType = "my";
  }
  return viewType;
};

// processFilter and column should always be true except portfolio
export const processFilterFromURL = (
  api?: GridApi,
  columnApi?: ColumnApi,
  processFilter = true,
  processColumn = true
): { [key: string]: any } => {
  const params = new URLSearchParams(location.search);
  let filterModel: { [key: string]: any } = {};
  params.forEach((value, key) => {
    if (key === "colIds") {
      if (processColumn) {
        const listValue = value.split(",");
        columnApi?.setColumnsVisible(listValue, true);
        columnApi?.moveColumns(listValue, 5);
      }
    } else {
      const [type, filter] = value.split(":");
      if (key === "daysToRenewal") {
        filterModel = {
          ...filterModel,
          [key]: {
            savedStatus: {
              from: Math.min(
                +filter,
                filterModel?.daysToRenewal?.savedStatus?.from || Infinity
              ),
              type: "lessThanOrEqual",
            },
          },
        };
      } else if (key === "priorityIndicatorRank") {
        filterModel = {
          ...filterModel,
          externalFilter: {
            filter: filter == "151" ? "Top 150" : "Top 500",
            type: "contains",
          },
        };
      } else if (key.startsWith("invoiceArr")) {
        if (type === "ge") {
          filterModel = {
            ...filterModel,
            [key]: {
              savedStatus: {
                ...filterModel[key]?.savedStatus,
                from: +filter,
                type: "greaterThanOrEqual",
              },
            },
          };
        } else {
          filterModel = {
            ...filterModel,
            [key]: {
              savedStatus: {
                ...filterModel[key]?.savedStatus,
                to: +filter,
                type: "between",
              },
            },
          };
        }
      } else if (key === "bpp") {
        filterModel = {
          ...filterModel,
          [key]: {
            filter: 0,
            type: "greaterThan",
            savedStatus: {
              yesOrNo: "yes",
            },
          },
        };
      } else {
        if (key in filterModel) {
          filterModel = {
            ...filterModel,
            [key]: {
              ...filterModel[key],
              filter: `${filterModel?.[key]?.filter}%-%${filter}`,
            },
          };
        } else {
          filterModel = { ...filterModel, [key]: { filter, type } };
        }
      }
    }
  });
  if (processFilter) {
    api?.setFilterModel(filterModel);
  }
  return filterModel;
};

export const transformActivityAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>,
  accountId?: string | undefined
) => {
  let result: {
    includeTasks: number;
    includeComments: number;
    searchEnable?: boolean;
    keyword?: string;
    sorts?: any;
    filters?: any;
    viewType?: string | undefined;
  } = {
    includeTasks: 1,
    includeComments: 1,
  };
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  let viewType = accountId ? "all" : "my";

  // create type for filterModel and sortModel
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  const sortModel: { colId: string; sort: string }[] = sortModelReq?.map(
    (x: { colId: string; sort: string }) => ({
      colId:
        x.colId in SORT_KEY_MAPPING
          ? SORT_KEY_MAPPING[x.colId as keyof typeof SORT_KEY_MAPPING]
          : x.colId,
      sort: x.sort,
    })
  );
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      customizedParam?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in ACTIVITY_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [ACTIVITY_KEY_MAPPING[k as keyof typeof ACTIVITY_KEY_MAPPING]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }
  // transform sort
  if (sortModel?.length > 0) {
    const sorts = sortModel.map((i) => ({ field: i.colId, sort: i.sort }));
    result = { ...result, sorts };
  }
  const filterKeys = Object.keys(filterModel);
  // transform filter
  const filters:
    | {
        field: string;
        operator: string;
        value:
          | string
          | number
          | string[]
          | boolean
          | { [key: string]: string[] }
          | null;
      }
    | any[] = [];
  if (filterKeys.length > 0) {
    const emailId = Cookies.get(EMAILID_COOKIE);
    const mockEmailId = Cookies.get(MOCK_USER_COOKIE);
    filterKeys.forEach((field) => {
      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalViewTypeFilter") {
            switch (filterModel?.externalViewTypeFilter?.filter) {
              case "All Activities":
                viewType = viewType;
                break;
              case "Created by Me":
                filters.push({
                  field: "createdBy",
                  operator: "eq",
                  value: emailId ?? mockEmailId,
                });
                break;
              case "Assigned to Me":
                filters.push({
                  field: "assigneeId",
                  operator: "eq",
                  value:
                    emailId?.split("@")?.[0] ?? mockEmailId?.split("@")?.[0],
                });
                break;
              case "Assigned to My Team":
                filters.push({
                  field: "assignedToMyTeam",
                  operator: "eq",
                  value: 1,
                });
                break;
            }
          } else if (field === "externalStatusFilter") {
            const [
              filterVal,
              ...extra
            ] = filterModel.externalStatusFilter.filter.split(
              / Activities| Tasks/
            );
            filters.push({
              field: filterModel.externalStatusFilter.filter.includes(
                "Activities"
              )
                ? "activityStatus"
                : "status",
              operator: "in",
              value: [
                filterVal,
                ...(extra.length > 1 ? extra.filter((x) => x) : []),
              ],
            });
          } else if (field === "externalOverdueFilter") {
            switch (filterModel?.externalOverdueFilter?.filter) {
              case "Overdue Activities":
                filters.push({
                  field: "overdue",
                  operator: KEYWORD_MAPPING.equals,
                  value: true,
                });
                break;
              case "with Overdue Tasks":
                filters.push({
                  field: "overdueTasks",
                  operator: KEYWORD_MAPPING.greaterThan,
                  value: 0,
                });
                break;
            }
          } else if (field === "externalRankFilter") {
            switch (filterModel?.externalRankFilter?.filter) {
              case "Top 150 Accounts":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 151,
                });
                break;
              case "Top 500 Accounts":
                filters.push({
                  field: "priorityIndicatorRank",
                  operator: KEYWORD_MAPPING.lessThan,
                  value: 501,
                });
                break;
            }
          } else if (field === "externalSearch") {
            // criteria from search bar, eg: true%|%apple
            const [
              field,
              operator,
              value,
            ] = filterModel.externalSearch.filter.split("%-%");
            // if empty will have value [""]
            if (field && operator && value) {
              filters.push({
                field,
                operator,
                value,
              });
            }
          } else if (field === "externalDueDateFilter") {
            const [typeFirst, ...typeSecond] = filterModel[
              field
            ]?.filter?.split("|");
            filters.push({
              field: "dueDateFilter",
              operator: "eq",
              value: {
                type: typeFirst,
                match: typeSecond?.join(""),
              },
            });
          } else if (field === "externalQuery") {
            const queries = filterModel.externalQuery.filter.split("%-%");
            queries.forEach((query) => {
              const values = query.split("%|%");
              if (values?.[0] !== "viewType")
                filters.push({
                  field: values?.[0] || "",
                  operator: values?.[1] || "",
                  value: values?.[2] || "",
                });
              // searchEnable =
              //   values?.[0] === "viewType" && values?.[2] === "all";
            });
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.contains,
              value: filterModel[field].filter,
            });
          }
          break;
        case "in":
          if (field === "subType" || field === "assigneeName") {
            let filterValue: string[] = [];
            filterValue = filterModel[field].filter.split("|").map((item) => {
              const [_type, ...value] = item.split("%-%");
              return value.join("");
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          }

          break;
        case "between":
          const [startDate, endDate] = filterModel[field].filter.split("|");
          filters.push({
            field,
            operator: KEYWORD_MAPPING.greaterThanOrEqual,
            value: startDate,
          });
          filters.push({
            field,
            operator: KEYWORD_MAPPING.lessThanOrEqual,
            value: endDate,
          });

          break;
        case "equals":
          if (field === "taskAttachment" || field === "taskNote") {
            filters.push({
              field: field,
              operator: filterModel[field].filter,
              value: null,
            });
          }
          const { userInputDateString } =
            filterModel[field]?.customizedParam ?? {};
          if (
            (field === "createdDate" ||
              field === "closedDate" ||
              field === "taskClosedDate") &&
            userInputDateString
          ) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThan,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
          } else if (
            (field === "dueDate" ||
              field === "taskDueDate" ||
              field === "activityDueDate") &&
            userInputDateString
          ) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThan,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value:
                  moment(new Date())
                    .utcOffset(0, true)
                    .format("YYYY-MM-DD HH:mm:ssZZ") ?? "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value:
                  moment(userInputDateString)
                    .utcOffset(0, true)
                    .format("YYYY-MM-DD HH:mm:ssZZ") ?? "",
              });
            }
          } else if (field === "lastModifiedDate" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThan,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value:
                  moment(new Date())
                    .utcOffset(0, true)
                    .format("YYYY-MM-DD HH:mm:ssZZ") ?? "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value:
                  moment(userInputDateString)
                    .utcOffset(0, true)
                    .format("YYYY-MM-DD HH:mm:ssZZ") ?? "",
              });
            }
          }
          break;
        default:
          filters.push({
            field,
            operator: KEYWORD_MAPPING[filterModel[field].type],
            value: filterModel[field].filter,
          });

          break;
      }
    });
  }
  return {
    ...result,
    filters,
    viewType,
  };
};

export const transformPlaybookAgGridRequest = (
  req: Partial<IServerSideGetRowsRequest>
) => {
  let result: {
    searchEnable?: boolean;
    keyword?: string;
    sorts?: any;
    filters?: any;
    includeInactive?: boolean;
  } = {};
  const { sortModel: sortModelReq, filterModel: filterModelReq } = req;
  let includeInactive = false;
  // create type for filterModel and sortModel
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  const sortModel: { colId: string; sort: string }[] = sortModelReq?.map(
    (x: { colId: string; sort: string }) => ({
      colId:
        x.colId in SORT_KEY_MAPPING
          ? SORT_KEY_MAPPING[x.colId as keyof typeof SORT_KEY_MAPPING]
          : x.colId,
      sort: x.sort,
    })
  );
  let filterModel: {
    [key: string]: {
      filterType: string;
      type: keyof typeof KEYWORD_MAPPING;
      filter: string;
      customizedParam?: any;
    };
  } = {};
  // transform field if need change, all defined in FILTER_KEY_MAPPING
  for (const [k, v] of Object.entries<{
    filterType: string;
    type: keyof typeof KEYWORD_MAPPING;
    filter: string;
  }>(filterModelReq)) {
    if (k in PlAYBOOK_KEY_MAPPING) {
      filterModel = {
        ...filterModel,
        [PlAYBOOK_KEY_MAPPING[k as keyof typeof PlAYBOOK_KEY_MAPPING]]: v,
      };
    } else {
      filterModel = { ...filterModel, [k]: v };
    }
  }
  // transform sort
  if (sortModel?.length > 0) {
    const sorts = sortModel.map((i) => ({ field: i.colId, sort: i.sort }));
    result = { ...result, sorts };
  }
  const filterKeys = Object.keys(filterModel);
  // transform filter
  const filters:
    | {
        field: string;
        operator: string;
        value:
          | string
          | number
          | string[]
          | boolean
          | { [key: string]: string[] }
          | null;
      }
    | any[] = [];
  if (filterKeys.length > 0) {
    filterKeys.forEach((field) => {
      switch (filterModel[field].type) {
        case "contains":
          // quick filters
          if (field === "externalViewTypeFilter") {
            switch (filterModel?.externalViewTypeFilter?.filter) {
              case "All":
                break;
              default:
                filters.push({
                  field: "category",
                  operator: "eq",
                  value: filterModel?.externalViewTypeFilter?.filter.toUpperCase(),
                });
                break;
            }
          } else if (field === "externalSearch") {
            // criteria from search bar, eg: true%|%apple
            const [
              field,
              operator,
              value,
            ] = filterModel.externalSearch.filter.split("%-%");
            // if empty will have value [""]
            if (field && operator && value) {
              filters.push({
                field,
                operator,
                value,
              });
            }
          } else if (field === "externalIncludeInactive") {
            includeInactive =
              filterModel?.externalIncludeInactive?.filter === "true";
            break;
          } else if (field === "externalProductFilter") {
            filters.push({
              field: "productTier",
              operator: "eq",
              value: filterModel[field].filter,
            });
          } else {
            filters.push({
              field,
              operator: KEYWORD_MAPPING.contains,
              value: filterModel[field].filter,
            });
          }
          break;
        case "in":
          if (field === "subType") {
            let filterValue: string[] = [];
            filterValue = filterModel[field].filter.split("|").map((item) => {
              const [_type, ...value] = item.split("%-%");
              return value.join("");
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          } else {
            const filterValue: string[] = [];
            filterModel[field].filter.split("|").forEach((item) => {
              if (item !== "") {
                filterValue.push(item);
              }
            });
            filters.push({
              field,
              operator: KEYWORD_MAPPING.in,
              value: filterValue,
            });
          }

          break;
        case "between":
          const [startDate, endDate] = filterModel[field].filter.split("|");
          filters.push({
            field,
            operator: KEYWORD_MAPPING.greaterThanOrEqual,
            value: startDate,
          });
          filters.push({
            field,
            operator: KEYWORD_MAPPING.lessThanOrEqual,
            value: endDate,
          });

          break;
        case "equals":
          const { userInputDateString } =
            filterModel[field]?.customizedParam ?? {};
          if (
            (field === "createdDate" || field === "closedDate") &&
            userInputDateString
          ) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThan,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
          } else if (field === "dueDate" && userInputDateString) {
            if (userInputDateString.indexOf("|") > -1) {
              const expandPlanDates = userInputDateString.split("|");
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value: expandPlanDates[0] ?? "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThan,
                value: expandPlanDates[1] ?? "",
              });
            } else {
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.greaterThanOrEqual,
                value:
                  moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ssZZ") ??
                  "",
              });
              filters.push({
                field: field,
                operator: KEYWORD_MAPPING.lessThanOrEqual,
                value: userInputDateString ?? "",
              });
            }
          }
          break;
        default:
          filters.push({
            field,
            operator: KEYWORD_MAPPING[filterModel[field].type],
            value: filterModel[field].filter,
          });

          break;
      }
    });
  }

  return {
    ...result,
    filters,
    includeInactive,
  };
};

export async function getBulkActionAccounts<T>(
  url: string,
  method: Method = "GET",
  params: any,
  data: any
) {
  const axios = useAxios({ headers: "page-size:1000;" });
  return await axios.request<T>({ method, url, data, params });
}

export const checkSelectAll = (prefixClassname?: string) => {
  const selectAllCheckBox = document.querySelector(
    `${prefixClassname ? `.${prefixClassname}` : ""} #selectAllCheckBox`
  );
  if (selectAllCheckBox) {
    // if select all checked, then return empty array
    if (selectAllCheckBox.getElementsByClassName("Mui-checked").length) {
      return true;
    } else {
      return false;
    }
  }
};

export const broadcastActivityChange = (id?: string, deleted = false) => {
  if (id) {
    const bc = new BroadcastChannel(ACTIVITY_SESSION_UPDATE_KEY);
    bc.postMessage([{ id, deleted }]);
  }
};

export function refreshDetailGrid<T>(
  rowData: T[],
  id = "",
  gridEvent?: GridReadyEvent
) {
  gridEvent?.api?.getDetailGridInfo(`detail_${id}`)?.api?.setRowData(rowData);

  const node = gridEvent?.api.getRowNode(id || "");
  if (node) {
    node.detailNode?.setRowHeight(
      clamp(rowData?.length || 0, 2, 5) * ROW_HEIGHT + 52
    );
    if (node.expanded) {
      node.setExpanded(false);
      node.setExpanded(true);
    }
  }
}

/**
 * @desc this function is to extract name from string like type%-%name
 */
export function getNameOfAConcatString(text?: string, separator = "%-%") {
  if (!text) return "";
  const [_type, ...rest] = text.split(separator);
  return rest.join(separator);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // if (orderBy === "taskStatus") {
  //   const order = ["Not Started", "In Progress", "Completed", "No Action"];
  //   if (
  //     order.indexOf(b[orderBy] as string) < order.indexOf(a[orderBy] as string)
  //   ) {
  //     return -1;
  //   }
  //
  //   if (
  //     order.indexOf(b[orderBy] as string) > order.indexOf(a[orderBy] as string)
  //   ) {
  //     return 1;
  //   }
  //   return 0;
  // } else {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function defaultComparator<T>(
  order: "asc" | "desc" | false | undefined,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const handleExternalUserColorLogic = (
  data: CIOrgDetail | SiteAccountType
) => {
  const exUser = data?.chExternalUser;
  if (data?.verifiedDomain && data?.verifiedDomain !== "N") {
    if (exUser === 0) {
      return "#00AB50";
    } else if (exUser && +exUser > 0) {
      return "#D4371C";
    } else {
      return "#333";
    }
  }
  if (
    (data?.verifiedDomain !== null ||
      (data as SiteAccountType)?.verifiedDomain === "N") &&
    exUser !== null
  ) {
    return "#D4371C";
  }
  return "#333";
};

export const handleRenewalRateClick = (url: string) => {
  if (!url) {
    return;
  }
  const [_link, _param, colId] = url.split("=");

  const filterModel = {
    [colId]: {
      filterType: "RadioFilter",
      type: "equals",
      filter: "Y",
    },
  };
  localStorage.setItem(PORTFOLIO_LAST_SEARCH_KEY, JSON.stringify(filterModel));

  window.open("/spng/portfolio", "_blank");
};

export const handleAdoptionPercent = (
  value: number,
  data: Account,
  type: string
) => {
  const total: number =
    Number(data?.[`adoptionCloud${type}` as keyof Account] || 0) +
    Number(data?.[`adoptionEdge${type}` as keyof Account] || 0) +
    Number(data?.[`adoptionUnknown${type}` as keyof Account] || 0);
  if (total === 0) {
    return (value || 0).toLocaleString() + " (0.00%)";
  } else {
    return `${(value || 0).toLocaleString()} (${(
      ((value || 0) / total) *
      100
    ).toFixed(2)}%)`;
  }
};

export const handleFFHorizontalScrollbar = () =>
  navigator?.userAgent?.indexOf("Firefox") != -1 ? 8 : undefined;
